<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Overview Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right">
          <!-- <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
      </b-row>
      <!-- Card stats -->

    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6">
      <card>
      <form class="needs-validation"
          @submit.prevent="submit">
      <!-- <div class="form-row">
        <b>Date Range & Type Project</b>
      </div> -->

      <div class="form-row">
        <b-col md="4">
                <base-input label="In Operation Since"
                      name="In Operation Since"
                      type="date"
                      v-model="model.start_date">
                </base-input>
        </b-col>

        <b-col md="4">
            <base-input label="TO"
                            name="TO"
                            type="date"
                            v-model="model.end_date">
                </base-input>
        </b-col>

        <b-col md="2">
            <label>Type of Project</label>
        </b-col>

        <b-col md="2">
          <b-form-checkbox
            id="Mandatory0"
            name="Mandatory"
            value="1"
            unchecked-value="0">
            <b>C & I Project</b>
          </b-form-checkbox>
          <b-form-checkbox
            id="Mandatory1"
            name="Mandatory"
            value="1"
            unchecked-value="0">
            <b>EPC Project</b>
          </b-form-checkbox>
          <b-form-checkbox
            id="Mandatory2"
            name="Mandatory"
            value="1"
            unchecked-value="0">
            <b>SN2 Project</b>
          </b-form-checkbox>
          <b-form-checkbox
            id="Mandatory3333"
            name="Mandatory"
            value="1"
            unchecked-value="0"
            v-model="model.checkboxes">
            <b>SN3 Project</b>
          </b-form-checkbox>
        </b-col>
      </div>
      </form>
      </card>

      <b-row>
        <b-col xl="2" md="6">
          <b-card no-body class="bg-gradient-default border-0" >
            <!-- Card body -->
          <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h6" class="text-uppercase text-muted mb-0 text-white">C & I COMPLETED - <b>76.8 MWp</b></b-card-title>
                  <!-- <span class="h4 font-weight-bold mb-0 text-white">50/62</span> -->
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card no-body class="bg-gradient-danger border-0">
            <!-- Card body -->
          <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h6" class="text-uppercase text-muted mb-0 text-white">SOLARNOVA 2 COMPLETED - <b>31.6 MWp</b> </b-card-title>
                  <!-- <span class="h4 font-weight-bold mb-0 text-white">50/62</span> -->
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card no-body class="bg-gradient-primary border-0">
            <!-- Card body -->
          <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h6" class="text-uppercase text-muted mb-0 text-white">SOLARNOVA 3 COMPLETED - <b>27.2 MWp</b></b-card-title>
                  <!-- <span class="h4 font-weight-bold mb-0 text-white">50/62</span> -->
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card no-body class="bg-gradient-info border-0">
            <!-- Card body -->
          <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h6" class="text-uppercase text-muted mb-0 text-white">Last 3 Months Avg C & I Completing Cycle Time - <b>27.2 MWp</b></b-card-title>
                  <!-- <span class="h4 font-weight-bold mb-0 text-white">50/62</span> -->
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card no-body class="bg-gradient-success border-0">
            <!-- Card body -->
          <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h6" class="text-uppercase text-muted mb-0 text-white">Total Completed Capacity - <b>27.2 MWp</b></b-card-title>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <latest-turn-on></latest-turn-on>
        </b-col>
          <b-col xl="7">
          <cni-project-status></cni-project-status>
        </b-col>
      </b-row>

      <b-row>
         <b-col xl="4">
          <b-card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">GORVENMENT</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">YEAR 2021 SN2 & SN3 Actual</h5>
            </template>
            <div class="chart-area">
              <bar-chart
                :height="480"
                :chart-data="barChartStacked.chartData"
                :extra-options="barChartStacked.extraOptions">
              </bar-chart>
            </div>
          </b-card>
        </b-col>
        <b-col xl="5">
          <card type="default" header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <!-- <h6 class="text-light text-uppercase ls-1 mb-1">GOVERNMENT</h6>
                <h5 class="h3 text-white mb-0">YEAR 2021 MONTHLY TARGET</h5> -->
                  <h6 class="surtitle text-white">GORVENMENT</h6>
              <!-- Title -->
              <h5 class="h3 mb-0 text-white"> YEAR 2021 Month Target</h5>
              </b-col>
              <b-col>
                <b-nav class="nav-pills justify-content-end">
                </b-nav>
              </b-col>
            </b-row>
            <line-chart
              :height="480"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </card>
        </b-col>

         <b-col xl="3">
          <solar-nova-2></solar-nova-2>
          <solar-nova-3></solar-nova-3>
        </b-col>
      </b-row>

    </b-container>

  </div>
</template>
<script>
  // Charts
  import VueApexCharts from 'vue-apexcharts';
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import { Charts } from "@/components/Charts/config";

  // Components
  import BaseProgress from '@/components/BaseProgress';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';

  // Lists
  // import ActivityFeed from './ActivityFeed';
  import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // Tabless
  // import LightTable from './LightTable';
  import CNIProjectStatus from './CNIProjectStatus.vue';
  import LatestTurnOn from './LatestTurnOn.vue';
  import TypeOfProject from './TypeOfProject.vue';
  import SolarNova2 from './SolarNova2.vue';
  import SolarNova3 from './SolarNova3.vue';
  import SolarRoof2 from './SolarRoof2.vue';
  // import PageVisitsTable from './PageVisitsTable';

  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'

  function randomScalingFactor() {
    return Math.round(Math.random() * 100);
  }

  export default {
    components: {
      //ActivityFeed,
      "apexchart": VueApexCharts,
      LineChart,
      BarChart,
      BaseProgress,
      RouteBreadCrumb,
      StatsCard,
      TaskList,
      //PageVisitsTable,

      "cni-project-status" : CNIProjectStatus,
      "latest-turn-on": LatestTurnOn,
      "type-of-project": TypeOfProject,
      "solar-nova-2": SolarNova2,
      "solar-nova-3": SolarNova3,
      "solar-roof-2": SolarRoof2,
      //LightTable,
      //UserList,
      //ProgressTrackList
    },
    created()
      {
          var currentDate = getcurrentDate()
          this.model.start_date = '2021-05-21'
          this.model.end_date = '2021-08-20'
      },
    data() {
      return {
      // series: [200, 120, 60,],
      // jobSummaryChartOptions:
      // {
      //     colors: ['#119ee1', '#0c765a', '#ef8215',],
      //     chart: {
      //     width: 380,
      //     type: 'donut',
      //     },
      //     plotOptions: {
      //       pie: {
      //       startAngle: -90,
      //       endAngle: 270,
      //       },
      //     },
      //     labels: ["Pending", "Completed", "Overdue",],
      //     dataLabels: {
      //     enabled: true
      //     },
      //     fill: {
      //     type: 'gradient',
      //     },

      //     legend: {
      //     formatter: function(val, opts) {
      //     return val + " - " + opts.w.globals.series[opts.seriesIndex]
      //     },
      //   position: 'bottom'
      //   },
      //   title: {
      //   text: ''
      //   },
      // },
      //  jobSummaryChartOptionsX:
      // {
      //     colors: ['#119ee1', '#0c765a', '#ef8215',],
      //     chart: {
      //     width: 380,
      //     type: 'donut',
      //     },
      //     plotOptions: {
      //       pie: {
      //       startAngle: -90,
      //       endAngle: 270,
      //       },
      //     },
      //     labels: ["Pending", "Completed", "Overdue",],
      //     dataLabels: {
      //     enabled: true
      //     },
      //     fill: {
      //     type: 'gradient',
      //     },

      //     legend: {
      //     formatter: function(val, opts) {
      //     return val + " - " + opts.w.globals.series[opts.seriesIndex]
      //     },
      //   position: 'bottom'
      //   },
      //   title: {
      //   text: ''
      //   },
      // },
        model: {
          start_date: null,
          end_date: null,
          checkboxes: 1,
        },
        barChartStacked: {
          chartData: {
            labels: ['March', 'April', 'May', 'June', 'July'],
            datasets: [{
              label: 'SN2 Actual',
              backgroundColor: Charts.colors.theme['success'],
              data: [
                1,1.5,1.2,2.9,2.6
              ]
            },
            {
              label: 'SN3 Actual',
              backgroundColor: Charts.colors.theme['primary'],
              data: [
               1.3,2.3,2.8,3.8,4.4
              ]
            }]

          },
          extraOptions: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            responsive: true,
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }
        },
        bigLineChart: {
          allData: [
            [22.2, 6.6, 2.8, 9.0, 2.6, 0.0, 0.9, 2.2, 0.0, 6.3],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['March', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },

        redBarChart: {
          chartData: {
            labels: ['March', 'April', 'May', 'June', 'July'],
            datasets: [{
              label: 'MWp',
              data: [1.0, 2.3, 2.8, 3.8, 4.4]
            }]
          },
          extraOptions: chartConfigs.blueChartOptions
        }
      };
    },
    methods: {

      initBigChart(index) {
        let chartData = {
          datasets: [
            {
              label: 'Year 2021 Monthly Target',
              data: this.bigLineChart.allData[index]
            }
          ],
          labels: ['March', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    mounted() {
      this.initBigChart(0);
    }
  };
</script>
<style></style>
