<template>
  <div>
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="12">
          <h6 class="h2 d-inline-block mb-0">Project Appproval Request</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item><router-link to="/"><i class="fas fa-home"></i></router-link></b-breadcrumb-item>
              <b-breadcrumb-item><a href="#">Dashboards</a></b-breadcrumb-item>
              <b-breadcrumb-item active aria-current="page">TRTP-2398 - Tengeh Reservoir Tender Project</b-breadcrumb-item>
            </b-breadcrumb>
          </nav>
        </b-col>
        <!-- <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col> -->
      </b-row>
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <b-card no-body class="bg-gradient-primary border-0">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Task</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">100/120</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="80"></base-progress>
                </b-col>
                <!-- <b-col md="auto">
                  <b-dropdown
                    no-caret right
                    toggle-class="btn btn-sm btn-neutral mr-0"
                  >
                    <template v-slot:button-content>
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <b-dropdown-item>Action</b-dropdown-item>
                    <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else here</b-dropdown-item>
                  </b-dropdown>
                </b-col> -->
              </b-row>
              <!-- <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
              </p> -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3" md="6">
          <b-card no-body class="bg-gradient-info border-0">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">One-Time Document</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">123/267</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="50"></base-progress>
                </b-col>
                <b-col md="auto">
                  <!-- <b-dropdown
                    no-caret right
                    toggle-class="btn btn-sm btn-neutral mr-0"
                  >
                    <template v-slot:button-content>
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <b-dropdown-item>Action</b-dropdown-item>
                    <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else here</b-dropdown-item>
                  </b-dropdown> -->
                </b-col>
              </b-row>
              <!-- <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
              </p> -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3" md="6">
          <b-card no-body class="bg-gradient-danger border-0">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Recurring Document</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">200/300</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="80"></base-progress>
                </b-col>
                <b-col md="auto">
                  <!-- <b-dropdown
                    no-caret right
                    toggle-class="btn btn-sm btn-neutral mr-0"
                  >
                    <template v-slot:button-content>
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <b-dropdown-item>Action</b-dropdown-item>
                    <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else here</b-dropdown-item>
                  </b-dropdown> -->
                </b-col>
              </b-row>
              <!-- <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
              </p> -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3" md="6">
          <b-card no-body class="bg-gradient-default border-0">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Request</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">50/62</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="90"></base-progress>
                </b-col>
                <b-col md="auto">
                  <!-- <b-dropdown
                    no-caret right
                    toggle-class="btn btn-sm btn-neutral mr-0"
                  >
                    <template v-slot:button-content>
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <b-dropdown-item>Action</b-dropdown-item>
                    <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else here</b-dropdown-item>
                  </b-dropdown> -->
                </b-col>
              </b-row>
              <!-- <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">See details</a>
              </p> -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
        <b-row>
          <b-col xl="12">
              <site-document-table></site-document-table>
          </b-col>
        </b-row>
    </b-container>


  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseHeader from '@/components/BaseHeader';
  import BaseProgress from '@/components/BaseProgress';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';

  // Lists
  import MembersCard from '@/views/Widgets/MembersCard';
  import TaskList from '@/views/Widgets/TaskList';

  // Tables

  import VectorMapCard from '@/views/Widgets/VectorMapCard'

  import SiteDocumentTable from './SiteDocumentTable'

  export default {
    components: {
      LineChart,
      BarChart,
      BaseHeader,
      BaseProgress,
      RouteBreadCrumb,
      StatsCard,
      TaskList,
      VectorMapCard,
      SiteDocumentTable,
      MembersCard
    },
    data() {
      return {
        bigLineChart: {
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          }
        }
      };
    }
  };
</script>
<style></style>
