<template >
  <card type="default" header-classes="bg-transparent" class="border border-white;">
    <b-row align-v="center" slot="header">
     &nbsp;&nbsp;<h5 class="h3 mb-0 text-white">CONSOLIDATED PROJECT SUMMARY</h5>
    </b-row>
    <b-row>
      <b-col xl="8">
        <apexchart type="donut" width="100%" height="300px" :options="ChartOptionsProjectMilestoneSummary" :series="seriesProjectMilestoneSummary"></apexchart>
      </b-col>
      <b-col xl="4">
        <b-row>
        <div style="color:white; font-size: 12px;"><b>Pending Milestone</b><br><h1 style="color:white;">{{ project_milestone_summary.total_pending }}</h1></div>
        </b-row>
        <br>
        <br>
        <b-row>
          <div style="color:white; font-size: 12px;"><b>Total Turn-On</b><br><h1 style="color:white;">{{ project_milestone_summary.total_turn_on }}</h1></div>
        </b-row>
      </b-col>
    </b-row>
  </card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import VueApexCharts from 'vue-apexcharts';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseProgress,
      "apexchart": VueApexCharts,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    created()
    {
      axios.get(apiDomain + 'dashboard/project_summary/1/2021-12-13', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_milestone_summary.total_pending = response.data.data.series[0]
          this.project_milestone_summary.total_turn_on = response.data.data.series[1]
          this.seriesProjectMilestoneSummary = response.data.data.series
          this.ChartOptionsProjectMilestoneSummary = {
            labels : response.data.data.label
          }
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Milestone Summary")
      } );

    },
    data() {
      return {
        project_milestone_summary:
        {
          total_pending: 0,
          total_turn_on: 0,
        },
        seriesProjectMilestoneSummary: [],
        ChartOptionsProjectMilestoneSummary:
        {
            colors: ['#119ee1', '#0c765a', '#5fc7bc', '#bc5fc7', '#EADE1F', '#ef8215', '#F43314', '#BA4A00'],
            chart: {
            width: 380,
            height: 100,
            type: 'pie',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: [],
            dataLabels: {
            enabled: true,
            },
            fill: {
            },
            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
      }
    }

  }
</script>
<style>
</style>
