<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Project Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col
          lg="6"
          cols="5"
          class="text-right"
          v-if="button_access.new_button == 'YES'"
        >
          <router-link to="/ProjectNew">
            <base-button size="sm" type="neutral">New Project</base-button>
          </router-link>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">Project Listing</h3>
          </template>
          <div>
            <b-col
              cols="12"
              class="
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                >
                </base-input>
              </div>
            </b-col>
            <b-col><br /></b-col>
            <el-table
              :data="queriedData"
              row-key="project_id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
            >
              <el-table-column
                label="Project Name"
                min-width="170px"
                prop="project_name"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.project_name
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Category"
                min-width="110px"
                prop="project_category"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.project_category.project_category
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Type"
                min-width="110px"
                prop="project_category"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.project_type.project_type
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Country"
                min-width="160px"
                prop="project_country"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.project_country
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Manager"
                min-width="150px"
                prop="project_manager"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.project_manager.name
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Progress"
                min-width="180px"
                prop="project_progress"
                sortable
              >
                <template v-slot="{ row }">
                  <div class="d-flex align-items-center">
                    <div>
                      <span class="font-weight-600 name mb-0 text-sm"
                        >{{ row.project_progress }}% &nbsp;
                      </span>
                    </div>
                    <div>
                      <base-progress
                        v-if="
                          row.project_progress > 0 && row.project_progress < 50
                        "
                        min-width="30px"
                        type="danger"
                        :value="row.project_progress"
                      />
                      <base-progress
                        v-else-if="
                          row.project_progress >= 50 &&
                          row.project_progress <= 90
                        "
                        min-width="30px"
                        type="info"
                        :value="row.project_progress"
                      />
                      <base-progress
                        v-else
                        min-width="30px"
                        type="success"
                        :value="row.project_progress"
                      />
                    </div>
                  </div>
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <badge class="badge-dot mr-4" type="">
                        <i
                          v-if="row.status.status_code == 'Delayed'"
                          class="bg-danger"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'On-Hold'"
                          class="bg-danger"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'Reviewed'"
                          class="bg-success"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'Completed'"
                          class="bg-success"
                        ></i>
                        <i v-else class="bg-info"></i>
                        <span class="font-weight-600 name mb-0 text-sm">{{
                          row.status.status_code
                        }}</span>
                      </badge>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{ $index, row }" class="d-flex">
                  <base-button
                    v-if="button_access.project_view == 'YES'"
                    @click.native="handleDetails($index, row)"
                    size="sm"
                    type="primary"
                  >
                    Dashboard
                  </base-button>
                  <base-button
                    v-if="
                      button_access.milestone_listing == 'YES' &&
                      myUserID.email == row.project_manager.email
                    "
                    @click.native="handleMilestone($index, row)"
                    size="sm"
                    type="primary"
                  >
                    Milestone
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
            <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Project Template & ONE Project Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import standardPaginationMix from "@/assets/js/standardPaginationMix.js";
import Fuse from "fuse.js";
import axios from "axios";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
} from "@/assets/js/config.js";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    // This code is not correct what you see here is for temporary testing purpose
    // var project_access = JSON.parse(window.localStorage.getItem('project_access'))
    // var project_milestone_access = JSON.parse(window.localStorage.getItem('project_milestone_access'))
    // this.button_access.new_button = project_access.access_create
    // this.button_access.milestone_listing = project_milestone_access.access_listing

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    // check if user is a project manager
    if (this.myUserID.role_id == 9) {
      // check if user is group leader
      axios
      .get(apiDomain + 'setup/project_engineer_group', { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          let group_listing_response = response.data.data;
          group_listing_response.forEach((e) => {
            if (e['engineer_group_leader']['id'] == this.myUserID.id) {
              this.groupId.push(e['engineer_group_id']);
            }
          });
          if (this.groupId != []) {
            this.groupMembers.push(this.myUserID.id);
            this.groupId.forEach((e_inner) => {
              axios
                .get(apiDomain + 'setup/project_engineer_group.show_by_group/' + e_inner, { headers: getHeader() })
                .then ( response => {
                  if (response.status === 200)
                  {
                    let group_member_response = response.data.data;
                    group_member_response.forEach((e_member) => {
                      let checkExist = this.groupMembers.includes(e_member['engineer_group_member']['id']);
                      if (!checkExist) {
                        this.groupMembers.push(e_member['engineer_group_member']['id']);
                      }
                    });
                  }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Project Engineer Group Member")
                });
            });
          } else {
            this.groupMembers.push(this.myUserID.id);
          }
      }
      })
      .catch ( error => {
        this.errorFunction(error, "Project Engineer Groups Listing")
      });
    }

    axios
      .get(apiDomain + "user_access/role_access.project_access", {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.button_access.new_button =
            response.data.data.project_modules[0].access_create;
          this.button_access.project_view =
            response.data.data.project_modules[0].access_show;
          this.button_access.milestone_listing =
            response.data.data.project_modules[2].access_listing;
          this.getprojectListing();
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Information");
      });
  },
  data() {
    return {
      propsToSearch: [
        "project_code",
        "project_name",
        "project_manager.name",
        "project_progress",
        "status.status_code",
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      myUserID: null,
      button_access: {
        new_button: null,
        project_view: null,
        milestone_listing: null,
      },
      groupId: [],
      groupMembers: [],
    };
  },
  methods: {
    getprojectListing() {
      axios
        .get(apiDomain + "project/project", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.fuseData();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Information");
        });
    },
    fuseData() {
      // this.fuseSearch = new Fuse(this.tableData, {
      //   keys: this.propsToSearch,
      //   threshold: 0.3,
      // });
      this.filterSNProjects();
      // if (this.myUserID.role_id == 9) {
      //   this.filterByUser();
      // }
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3,
      });
    },
    filterSNProjects(){
        var tempTableData = [];
        this.tableData.forEach((e) => {
            if (e['project_type']['project_type_id'] == 4){
                tempTableData.push(e);
            }
        });
        this.tableData = tempTableData;
    },
    filterByUser(){
      var tempTableData = [];
      this.groupMembers.forEach((e) => {
        this.tableData.forEach((e_inner) => {
          if (e_inner['project_manager']['id'] == e) {
            tempTableData.push(e_inner);
          }
        });
      });
      this.tableData = tempTableData;
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    handleDetails(index, row) {
      this.$router.push({ path: "projectDashboard/" + row.project_id });
    },
    handleMilestone(index, row) {
      this.$router.push({ path: "projectMilestoneListing/" + row.project_id });
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
