<template>
  <div>
    <div class="header pb-6 d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-default opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex align-items-center">
        <b-row >
          <b-col lg="7" md="10">
            <h1 class="display-2 text-white">Hello, {{userInfo()}}</h1>
            <p class="text-white mt-0 mb-5">This is your profile page. </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12" class="order-xl-1">
          <edit-profile-form></edit-profile-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditMyProfileForm.vue';
  import UserCard from './UserProfile/UserCard.vue';
  import ProgressTrackList from '@/views/Dashboard/ProgressTrackList';
  import {getUserInfo} from '@/assets/js/config.js'

  export default {
    components: {
      EditProfileForm,
      UserCard,
      ProgressTrackList
    },
    methods: {
      userInfo()
      {
        return getUserInfo().name;
      }
    }
  };
</script>
<style>
  .profile-header {

    background-size: cover;
    background-position: center top;
    min-height: 500px;
  }
</style>
