<template>
  <card type="default" header-classes="bg-transparent" class="border border-white;">
    <b-row align-v="center" slot="header">
     &nbsp;&nbsp;<h5 class="h3 mb-0 text-white">CONSOLIDATED OVERDUE TURN ON</h5>
    </b-row>
    <b-row>
      <b-col xl="8">
        <apexchart type="donut" width="100%" height="300px" :options="ChartOptions" :series="series"></apexchart>
      </b-col>
      <b-col xl="4">
        <b-row>
        <div style="color:white; font-size: 12px;"><b>Total Overdue (MWp) </b><br><h1 style="color:white;">72.66 </h1></div>
        </b-row>
        <br>
        <br>
        <b-row>
          <div style="color:white; font-size: 12px;"><b>Highest Overdue : Project Less Than 1 MWp</b></div>
        </b-row>
      </b-col>
    </b-row>
  </card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import VueApexCharts from 'vue-apexcharts';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    components: {
      BaseProgress,
      "apexchart": VueApexCharts,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        seriesOverDueTurnOn: [41,6,5,4],
        ChartOptionsOverDueTurnOn:
        {
            colors: ['#119ee1', '#0c765a', '#ef8215', '#F43314'],
            chart: {
            width: 380,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: ["Project < 1 MWp", "Project 1 - 5 MWp", "Project 5 - 10 MWp", "Project > 10 MWp"],
            dataLabels: {
            enabled: true
            },
            fill: {
            // type: 'gradient',
            },

            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
      }
    }

  }
</script>
<style>
</style>
