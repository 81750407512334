<template>
    <section>
        <div class="content">
            <base-header class="pb-6">
                <b-row align-v="center" class="py-4">
                    <b-col cols="7" lg="6">
                        <h6 class="h2 text-white d-inline-block mb-0">
                            Site
                        </h6>
                        <nav
                                aria-label="breadcrumb"
                                class="d-none d-md-inline-block ml-md-4"
                        >
                        </nav>
                    </b-col>
                </b-row>
            </base-header>
            <b-container fluid class="mt--6">
                <div>
                    <card
                            class="card-border-diagonal p-0"
                            style="border: 1px solid black;"
                    >
                        <b-row class="my--4">
                            <b-col md="2" class="b-col-centered">
                                <div class="scroll-follower">
                                    <base-button size="md" class="p-2 m-2 button-css" @click="redirectToProjectDashboard">Project Profile</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToSite">Site Profile</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToIfaIfcModule">IFC/IFA/Module</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToProjectPlanning">Project Planning</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToGanttChart">Gantt Chart</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToTurnOnPlanning">Turn On Planning</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToUploadCif">Upload CIF</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToDisplayCif">Display CIF</base-button>
                                </div>
                            </b-col>

                            <b-col md="8" class="bg-custom">
                                <div id="site-profile">
                                    <h3 class="mt-4">Site Profile</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <b-row>
                                            <b-col>
                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Site Name</p>
                                                        <p class="form-content">{{ model.site_name }}</p>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Site Status"
                                                                    name="Site Status"
                                                                    v-model="model.status_id">
                                                            <select class="form-control" v-model="model.status_id">
                                                                <option Selected disabled value="x">-- Please Select Site Status --</option>
                                                                <option v-for="item in site_status" :key="item.status_id" v-bind:value="item.status_id" :disabled="item.disabled">{{ item.status_code }}</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Address</p>
                                                        <p class="form-content">{{ model.site_address }}</p>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <p class="form-label">Postal Code</p>
                                                        <p class="form-content">{{ model.site_postal_code }}</p>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="EPC"
                                                                    name="EPC"
                                                                    v-model="model.contractor_id">
                                                            <select class="form-control" v-model="model.contractor_id">
                                                                <option Selected disabled value="x">-- Please Select A Contractor --</option>
                                                                <option v-for="item in contractors" :key="item.contractor_id" v-bind:value="item.contractor_id">{{ item.contractor_code }}</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Site Code"
                                                                    name="Site Code"
                                                                    v-model="model.site_code">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Roof Handover Date</p>
                                                        <p class="form-content">{{ additional_model.roof_handover_date | formatDate }}</p>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="COD"
                                                                    name="COD"
                                                                    type="date"
                                                                    v-model="model.site_cod_date">
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <p class="form-label">Planned Turn On</p>
                                                        <p class="form-content">{{ model.site_target_turn_on_date | formatDate }}</p>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Actual Turn On Date"
                                                                    name="Actual Turn On Date"
                                                                    type="date"
                                                                    v-model="model.site_actual_turn_on_date">
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="As Built Capacity (kWh)"
                                                                    name="As Built Capacity (kWh)"
                                                                    v-model="model.site_total_capacity">
                                                        </base-input>
                                                    </b-col>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="3">
                                                <p class="form-label">Attachment:</p>
                                            </b-col>

                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click.prevent="$refs.file.click()">Select File</base-button>
                                                <input
                                                        type="file"
                                                        ref="file"
                                                        style="display:none"
                                                        @change="onChange"
                                                />
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="3">
                                                <div v-if="!site_attachment.file">No file selected.</div>
                                                <div v-else>{{ site_attachment.file.name }}</div>
                                            </b-col>

                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadFile">Upload File</base-button>
                                            </b-col>
                                        </b-row>

                                        <p class="form-label" v-if="site_attachments.length > 0">File Name</p>

                                        <hr v-if="site_attachments.length > 0" style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">

                                        <b-row v-if="site_attachments.length > 0">
                                            <b-col>
                                                <div v-for="attachment in site_attachments" :key="attachment.site_file_id">
                                                    <b-row>
                                                        <b-col md="6">{{ attachment.site_file_name }}</b-col>
                                                        <b-col md="2"><base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 80%;" @click="downloadFile(attachment.file.url, attachment.site_file_name)">Download</base-button></b-col>
                                                        <b-col md="2"><base-button size="md" class="p-1 m-2" style="background-color: #C00000; width: 80%;" @click="deleteFile(attachment.site_file_id)">Delete</base-button></b-col>
                                                    </b-row>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <hr v-if="site_attachments.length > 0" style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">

                                        <!-- <b-row>
                                            <b-col md="10" class="text-right">
                                                set active epc
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="setActiveSiteContractor()">Save EPC</base-button>
                                            </b-col>
                                        </b-row> -->

                                        <b-row>
                                            <b-col md="10" class="text-right">
                                                <!-- save site data -->
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData(); setActiveSiteContractor();">Save</base-button>
                                                <!-- <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData()">Save</base-button> -->
                                            </b-col>
                                        </b-row>
                                    </card>
                                </div>

                                <div id="ifc-ifa-module">
                                    <h3>IFA/IFC/Module Allocation</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <b-row>
                                            <b-col>
                                                <h3>Module Allocation</h3>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Brand"
                                                                    name="Brand"
                                                                    v-model="model.site_module_brand_id">
                                                            <select class="form-control" v-model="model.site_module_brand_id" @change="updateModuleAllocationWattClass()">
                                                                <option Selected disabled value="x">-- Please Select A Module Brand --</option>
                                                                <option :value=null>-- Please Select A Module Brand --</option>
                                                                <option
                                                                        v-for="item in module_brands"
                                                                        :key="item.module_brand_id"
                                                                        v-bind:value="item.module_brand_id">
                                                                    {{ item.module_brand_id_name }}
                                                                </option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Number of Module"
                                                                    name="Number of Module"
                                                                    v-model="model.site_module_quantity"
                                                                    @input="calculateModuleAllocationCapacity()">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Watt Class</p>
                                                        <p class="form-content">{{ model.site_module_capacity }}</p>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <p class="form-label">Capacity (kWh)</p>
                                                        <p class="form-content">{{ model.site_module_allocation_total_capacity }}</p>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="12">
                                                        <label for="textarea">Remarks</label>
                                                        <b-form-textarea
                                                                id="textarea"
                                                                v-model="model.site_ifc_ifa_module_remarks"
                                                                placeholder=""
                                                                rows="5"
                                                        ></b-form-textarea>
                                                    </b-col>
                                                </div>

                                                <br>

                                                <h3>Drawing</h3>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Brand"
                                                                    name="Brand"
                                                                    v-model="model.site_drawing_module_brand_id">
                                                            <select class="form-control" v-model="model.site_drawing_module_brand_id" @change="updateDrawingWattClass()">
                                                                <option Selected disabled value="x">-- Please Select A Module Brand --</option>
                                                                <option :value=null>-- Please Select A Module Brand --</option>
                                                                <option
                                                                        v-for="item in module_brands"
                                                                        :key="item.module_brand_id"
                                                                        v-bind:value="item.module_brand_id">
                                                                    {{ item.module_brand_id_name }}
                                                                </option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Number of Module"
                                                                    name="Number of Module"
                                                                    v-model="model.site_drawing_module_quantity"
                                                                    @input="calculateDrawingCapacity()">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Watt Class</p>
                                                        <p class="form-content">{{ model.site_drawing_module_capacity }}</p>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <p class="form-label">Capacity (kWh)</p>
                                                        <p class="form-content">{{ model.site_drawing_total_capacity }}</p>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="IFA Date"
                                                                    name="IFA Date"
                                                                    type="date"
                                                                    v-model="model.site_drawing_ifa_date">
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="IFC Date"
                                                                    name="IFC Date"
                                                                    type="date"
                                                                    v-model="model.site_drawing_ifc_date">
                                                        </base-input>
                                                    </b-col>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="10" class="text-right">
                                                <!-- save site data -->
                                            </b-col>
                                            <b-col md="2">
                                                <!-- <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData(); setActiveSiteContractor();">Save</base-button> -->
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData()">Save</base-button>
                                            </b-col>
                                        </b-row>
                                    </card>
                                </div>

                                <div id="project-planning">
                                    <h3>Project Planning & Schedule</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css-grey">
                                        <b-row>
                                            <b-col>
                                                <div class="form-row">
                                                    <b-col md="4">
                                                        <base-input label="NTP Applicable"
                                                                    name="NTP Applicable"
                                                                    v-model="additional_model.ntp_applicable">
                                                            <select class="form-control" v-model="additional_model.ntp_applicable">
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="4">
                                                        <base-input label="NTP Issued"
                                                                    name="NTP Issued"
                                                                    v-model="additional_model.ntp_issued">
                                                            <select class="form-control" v-model="additional_model.ntp_issued" :disabled="additional_model.ntp_applicable != 1">
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="4">
                                                        <base-input label="NTP Issue Date"
                                                                    name="NTP Issue Date"
                                                                    type="date"
                                                                    v-model="additional_model.ntp_issue_date" :disabled="(additional_model.ntp_issued != 1) || (additional_model.ntp_applicable != 1)">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Internal Project Handover Completed?"
                                                                    name="Internal Project Handover Completed?"
                                                                    v-model="additional_model.internal_project_handover_completed">
                                                            <select class="form-control" v-model="additional_model.internal_project_handover_completed">
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Internal Project Handover Date"
                                                                    name="Internal Project Handover Date"
                                                                    type="date"
                                                                    v-model="additional_model.internal_project_handover_date" :disabled="additional_model.internal_project_handover_completed != 1">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Kick Off Meeting with Client Conducted?"
                                                                    name="Kick Off Meeting with Client Conducted?"
                                                                    v-model="additional_model.kick_off_meeting_completed">
                                                            <select class="form-control" v-model="additional_model.kick_off_meeting_completed">
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Kick Off Meeting with Client Date"
                                                                    name="Kick Off Meeting with Client Date"
                                                                    type="date"
                                                                    v-model="additional_model.kick_off_meeting_date" :disabled="additional_model.kick_off_meeting_completed != 1">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Roof Handover Completed?"
                                                                    name="Roof Handover Completed?"
                                                                    v-model="additional_model.roof_handover_completed">
                                                            <select class="form-control" v-model="additional_model.roof_handover_completed">
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Roof Handover Date"
                                                                    name="Roof Handover Date"
                                                                    type="date"
                                                                    v-model="additional_model.roof_handover_date" :disabled="additional_model.roof_handover_completed != 1">
                                                        </base-input>
                                                    </b-col>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="10" class="text-right">
                                                <!-- save additional site data (project planning & schedule, turn on checking) -->
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteAdditionalInfo()">Save</base-button>
                                            </b-col>
                                        </b-row>

                                        <div class="form-row" v-if="site_milestones.length == 0">
                                            <b-col md="10">
                                                <base-input label="Set Milestone Template (Note: Can only be imported ONCE.)"
                                                            name="Milestone Template"
                                                            v-model="model.milestone_template_id">
                                                    <select class="form-control" v-model="model.milestone_template_id">
                                                        <option Selected disabled value="x">-- Please Select A Milestone Template --</option>
                                                        <option v-for="item in milestone_template" :key="item.milestone_template_id" v-bind:value="item.milestone_template_id">{{ item.milestone_template_name }}</option>
                                                    </select>
                                                </base-input>
                                            </b-col>

                                            <b-col md="1">
                                                <br>
                                                <base-button size="sm" type="primary" style="background-color: #375623;" @click="checkMilestoneDetail()">Milestone Details</base-button>
                                            </b-col>

                                            <b-col md="1">
                                                <br>
                                                <base-button size="sm" type="primary" style="background-color: #375623;" @click="importSiteMilestones()">Import Milestones</base-button>
                                            </b-col>
                                        </div>

                                        <b-row v-if="site_milestones.length > 0">
                                            <b-col md="2"><p class="form-label">Milestone</p></b-col>
                                            <b-col md="2"><p class="form-label">Planned Start Date</p></b-col>
                                            <b-col md="2"><p class="form-label">Planned End Date</p></b-col>
                                            <b-col md="1"><p class="form-label">Total Days</p></b-col>
                                            <b-col md="5"><p class="form-label">Remarks</p></b-col>
                                        </b-row>

                                        <b-row v-for="(row, index) in site_milestones" :key="index" class="no-gutters">
                                            <b-col md="2">{{ row.site_milestone_code }}</b-col>
                                            <b-col md="2">
                                                <base-input type="date" v-model="row.planned_start_date" :max="row.planned_end_date" @change="calculateTotalPlannedDay(index,'planned_start_date')"></base-input>
                                            </b-col>
                                            <b-col md="2">
                                                <base-input type="date" v-model="row.planned_end_date" :min="row.planned_start_date" @change="calculateTotalPlannedDay(index,'planned_end_date')"></base-input>
                                            </b-col>
                                            <b-col md="1">
                                                <base-input type="number" v-model="row.total_planned_day" :min="0" @change="calculateTotalPlannedDay(index,'total_planned_day')"></base-input>
                                            </b-col>
                                            <b-col md="5">
                                                <base-input v-model="row.remarks"></base-input>
                                            </b-col>
                                        </b-row>

                                        <b-row v-if="site_milestones.length > 0">
                                            <b-col md="10" class="text-right">
                                                <!-- save site milestone data -->
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteMilestoneData()">Save</base-button>
                                            </b-col>
                                        </b-row>
                                    </card>
                                </div>

                                <div id="gantt-chart">
                                    <h3>Gantt Chart</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <div id="chart" style="border: 1px solid #375623;">
                                            <apexchart type="rangeBar" height="350" :options="chartOptions" :series="series" @dataPointSelection="dataPointSelectionHandler"></apexchart>
                                        </div>

                                        <!-- <b-row>
                                            <b-col md="10" class="text-right">
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteMilestoneData()">Save</base-button>
                                            </b-col>
                                        </b-row> -->
                                    </card>
                                </div>

                                <div id="turn-on-checking">
                                    <h3>Turn On Checking</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css-grey">
                                        <b-row>
                                            <b-col md="2"></b-col>
                                            <b-col md="2"><p class="form-label">Checked?</p></b-col>
                                            <b-col md="2"><p class="form-label">Any Issue?</p></b-col>
                                            <b-col md="6"><p class="form-label">Remarks</p></b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="2"><p class="form-label">COPS/T&C</p></b-col>
                                            <b-col md="2">
                                                <base-input v-model="additional_model.cops_tnc_checked">
                                                    <select class="form-control" v-model="additional_model.cops_tnc_checked">
                                                        <option value="1">YES</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </base-input>
                                            </b-col>
                                            <b-col md="2">
                                                <base-input v-model="additional_model.cops_tnc_issue">
                                                    <select class="form-control" v-model="additional_model.cops_tnc_issue" :disabled="additional_model.cops_tnc_checked != 1">
                                                        <option value="1">YES</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </base-input>
                                            </b-col>
                                            <b-col md="6">
                                                <base-input v-model="additional_model.cops_tnc_remarks" :disabled="additional_model.cops_tnc_checked != 1">
                                                </base-input>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="2"><p class="form-label">O&M Handover Doc</p></b-col>
                                            <b-col md="2">
                                                <base-input v-model="additional_model.onm_handover_checked">
                                                    <select class="form-control" v-model="additional_model.onm_handover_checked">
                                                        <option value="1">YES</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </base-input>
                                            </b-col>
                                            <b-col md="2">
                                                <base-input v-model="additional_model.onm_handover_issue">
                                                    <select class="form-control" v-model="additional_model.onm_handover_issue" :disabled="additional_model.onm_handover_checked != 1">
                                                        <option value="1">YES</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </base-input>
                                            </b-col>
                                            <b-col md="6">
                                                <base-input v-model="additional_model.onm_handover_remarks" :disabled="additional_model.onm_handover_checked != 1">
                                                </base-input>
                                            </b-col>
                                        </b-row>

                                        <b-row v-if="additional_model.turn_on_checking_approved == 0">
                                            <b-col md="2">
                                                <p class="form-label">Approval to close:</p>
                                            </b-col>
                                            <b-col md="auto">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="approveToCloseTurnOnChecking()">Approve</base-button>
                                            </b-col>
                                        </b-row>

                                        <b-row v-if="additional_model.turn_on_checking_approved == 1">
                                            <b-col md="2">
                                                <p class="form-label">Approval Date:</p>
                                            </b-col>
                                            <b-col md="auto">
                                                {{ additional_model.turn_on_checking_approval_date }}
                                            </b-col>
                                        </b-row>

                                        <b-row v-if="additional_model.turn_on_checking_approved == 1">
                                            <b-col md="2">
                                                <p class="form-label">Approved By:</p>
                                            </b-col>
                                            <b-col md="auto">
                                                {{ additional_model.turn_on_checking_approver.name }}
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="10" class="text-right">
                                                <!-- save additional site data (project planning & schedule, turn on checking) -->
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteAdditionalInfo()">Save</base-button>
                                            </b-col>
                                        </b-row>
                                    </card>
                                </div>

                                <div id="upload-cif">
                                    <h3>Upload Customer Info Form</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <b-row>
                                            <b-col md="3">
                                                Select the file to upload:
                                            </b-col>

                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click.prevent="$refs.file.click()">Select File</base-button>
                                                <input
                                                        type="file"
                                                        ref="file"
                                                        style="display:none"
                                                        @change="onChangeCif"
                                                />
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="3">
                                                <div v-if="!cif_file">No file selected.</div>
                                                <div v-else>
                                                    <base-input label="Sheet To Import"
                                                                name="Sheet To Import"
                                                                v-model="selected_sheet">
                                                        <select class="form-control" v-model="selected_sheet" @click="loadSheetNames" @change="updateRangeToSelect(); showUploadingData();">
                                                            <option Selected disabled value="x">-- Please Select A Sheet To Import --</option>
                                                            <option v-for="sheet in sheets" :key="sheet.sheetId" v-bind:value="sheet.name">{{ sheet.name }}</option>
                                                        </select>
                                                    </base-input>
                                                </div>
                                            </b-col>

                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadCif" v-if="converted_temp_data"
                                                >Upload File</base-button
                                                >
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadCif" v-else disabled
                                                >Upload File</base-button
                                                >
                                            </b-col>
                                        </b-row>

                                        <div>
                                            <xlsx-read ref="excelRead" :file="cif_file">
                                                <xlsx-json ref="excelJson" :sheet="selected_sheet" :options="{ range: selected_range, blankrows: false }">
                                                </xlsx-json>
                                            </xlsx-read>
                                        </div>
                                    </card>
                                </div>

                                <div id="display-cif">
                                    <h3>Customer Info Form - Stage 1: Technical Info (Engineering)</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <!-- <b-row>
                                            <b-col>
                                                <div class="form-row"> -->
                                        <div>
                                            <div v-for="item in additional_model.cif_technical_info" :key="item.column_name">
                                                <div v-if="item.unit || item.value">
                                                    <template v-if="item.unit !== 'no.'
                                                            && item.unit !== 'drop down'
                                                            && item.unit !== 'text'
                                                            && item.unit !== 'Yes / No'">
                                                        {{ item.column_name }}: {{ item.value }} [{{ item.unit }}]
                                                    </template>
                                                    <template v-else>
                                                        {{ item.column_name }}: {{ item.value }}
                                                    </template>
                                                </div>
                                                <div v-else>
                                                    <br>
                                                    <strong>{{ item.column_name }}</strong>
                                                    <br>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- </div>
                                    </b-col>
                                </b-row> -->
                                    </card>

                                    <h3>Customer Info Form - Stage 1: Project Info (BD)</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <!-- <b-row>
                                            <b-col>
                                                <div class="form-row"> -->
                                        <div>
                                            <div v-for="item in additional_model.cif_project_info" :key="item.column_name">
                                                <div v-if="item.column_4 == 'Email / Phone Number'">
                                                    <br>
                                                    <b-row>
                                                        <b-col md="4"><strong>{{ item.column_1 }}</strong></b-col>
                                                        <b-col md="2"><strong>{{ item.column_2 }}</strong></b-col>
                                                        <b-col md="2"><strong>{{ item.column_3 }}</strong></b-col>
                                                        <b-col md="4"><strong>{{ item.column_4 }}</strong></b-col>
                                                    </b-row>
                                                    <hr style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">
                                                </div>
                                                <div v-else-if="item.isEmail">
                                                    <b-row>
                                                        <b-col md="4">{{ item.column_1 }}</b-col>
                                                        <b-col md="2">{{ item.column_2 }}</b-col>
                                                        <b-col md="2">{{ item.column_3 }}</b-col>
                                                        <b-col md="4">{{ item.column_4 }}</b-col>
                                                    </b-row>
                                                    <hr v-if="item.column_1 == 'Overall Person in Charge (if any)'" style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">
                                                </div>
                                                <div v-else-if="item.column_2 || item.column_3 || item.column_4">
                                                    <template v-if="item.column_2 !== 'text'
                                                                && item.column_2 !== 'nos'
                                                                && item.column_2 !== 'drop down'
                                                                && item.column_2 !== 'Yes / No'
                                                                && item.column_2 !== 'date'
                                                                && item.column_2 !== 'Have / Don\'t Have'">
                                                        {{ item.column_1 }}: {{ item.column_3 }} [{{ item.column_2 }}]
                                                    </template>
                                                    <template v-else>
                                                        {{ item.column_1 }}: {{ item.column_3 }}
                                                    </template>
                                                </div>
                                                <div v-else>
                                                    <br>
                                                    <strong>{{ item.column_1 }}</strong>
                                                    <br>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- </div>
                                    </b-col>
                                </b-row> -->
                                    </card>
                                </div>
                            </b-col>

                            <b-col md="2">
                                <!-- empty for side key contents -->
                                <div class="scroll-follower-right form-custom-css">
                                    <h2 class="h2-key-contacts">Key Contacts</h2>
                                    <br>
                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Project Engineer</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_manager }}</p>
                                        </b-col>
                                    </div>

                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Engineer</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_engineer }}</p>
                                        </b-col>
                                    </div>

                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Business Development</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_business_development }}</p>
                                        </b-col>
                                    </div>

                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Safety Officer</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_safety }}</p>
                                        </b-col>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </card>
                </div>
            </b-container
            >
        </div>

        <modal
                id="gantt_modal"
                :show.sync="isSiteMilestoneVisible"
                size="xl"
                body-classes="p-0"
                title="Site Milestone">
            <card>
                <template v-if="site_milestones.length > 0">
                    <h1 class="text-center">Milestone: {{ site_milestones[selectedDataPointIndex].site_milestone_code }}</h1>
                    <div class="form-row">
                        <b-col md="4">
                            <base-input label="Planned Start Date"
                                        name="Planned Start Date"
                                        type="date"
                                        v-model="site_milestones[selectedDataPointIndex].planned_start_date"
                                        disabled>
                            </base-input>
                        </b-col>

                        <b-col md="4">
                            <base-input label="Planned End Date"
                                        name="Planned End Date"
                                        type="date"
                                        v-model="site_milestones[selectedDataPointIndex].planned_end_date"
                                        disabled>
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input label="Total Days"
                                        name="Total Days"
                                        type="number"
                                        v-model="site_milestones[selectedDataPointIndex].total_planned_day"
                                        disabled>
                            </base-input>
                        </b-col>
                    </div>
                    <div class="form-row">
                        <b-col md="4">
                            <base-input label="Actual Start Date"
                                        name="Actual Start Date"
                                        type="date"
                                        v-model="site_milestones[selectedDataPointIndex].actual_start_date"
                                        :max="site_milestones[selectedDataPointIndex].actual_end_date"
                                        @change="calculateTotalPlannedDay(selectedDataPointIndex,'actual_start_date')">

                            </base-input>
                        </b-col>

                        <b-col md="4">
                            <base-input label="Actual End Date"
                                        name="Actual End Date"
                                        type="date"
                                        v-model="site_milestones[selectedDataPointIndex].actual_end_date"
                                        :min="site_milestones[selectedDataPointIndex].actual_start_date"
                                        @change="calculateTotalPlannedDay(selectedDataPointIndex,'actual_end_date')">

                            </base-input>
                        </b-col>

                        <b-col md="4">
                            <base-input label="Total Days"
                                        name="Total Days"
                                        type="number"
                                        v-model="site_milestones[selectedDataPointIndex].total_actual_day"
                                        :min="0"
                                        @input="specificCalculateActualDate">
                            </base-input>
                        </b-col>
                    </div>
                </template>
                <!-- <div style="float: right;">
                  <base-button size="md" class="p-2" style="background-color: #375623;" @click="closeAndUpdateGanttChart">Update Gantt Chart</base-button>
                </div> -->

                <b-row>
                    <b-col md="10" class="text-right">
                        <!-- update gantt chart, still need to click on save site milestone data to store data -->
                    </b-col>
                    <b-col md="2">
                        <!-- <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData(); setActiveSiteContractor();">Save</base-button> -->
                        <!-- <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="closeAndUpdateGanttChart()">Update Gantt Chart</base-button> -->
                        <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="closeAndUpdateGanttChart()">Save</base-button>
                    </b-col>
                </b-row>
            </card>
        </modal>
    </section>
</template>
<script>
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
        getcurrentDate,
    } from "@/assets/js/config.js";
    import axios from "axios";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
    import jsonFormData from 'json-form-data';
    import VueApexCharts from "vue-apexcharts";
    import moment from "moment";
    import XlsxRead from "../../../../node_modules/vue-xlsx/dist/components/XlsxRead";
    import XlsxTable from "../../../../node_modules/vue-xlsx/dist/components/XlsxTable";
    import XlsxJson from "../../../../node_modules/vue-xlsx/dist/components/XlsxJson";

    export default {
        mixins: [ErrorFunctionMixin],
        components: {
            apexchart: VueApexCharts,
            XlsxRead,
            XlsxTable,
            XlsxJson,
        },
        created() {
            this.currentDate = getcurrentDate();

            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;

            this.getProjectSiteStatusListing();
            this.getContractorListing();
            this.getModuleBrandListing();
            this.getMilestoneTemplateListing();
            this.getDashboardData();
            this.getDashboardAdditionalInfo();
            this.getProjectSiteMilestoneData();
            this.getContractorFromSite();
            this.getSiteAttachmentListing();
        },
        data() {
            return {
                model: {
                    site_id: null,
                    project_id: null,
                    group_id: null,
                    site_name: null,
                    status_id: 'x',
                    site_address: null,
                    site_postal_code: null,
                    contractor_id: 'x',
                    site_code: null,
                    roof_handover_date: null, // CHECK WITH ANDREW
                    site_cod_date: null,
                    site_target_turn_on_date: null,
                    site_actual_turn_on_date: null,
                    site_total_capacity: null, // CHECK WITH ANDREW
                    site_drawing_module_brand_id: 'x',
                    site_drawing_module_quantity: 0,
                    site_drawing_module_capacity: 0,
                    site_drawing_total_capacity: 0,
                    site_drawing_ifa_date: null,
                    site_drawing_ifc_date: null,
                    site_module_brand_id: 'x',
                    site_module_quantity: 0,
                    site_module_capacity: 0,
                    site_module_allocation_total_capacity: 0,
                    site_ifc_ifa_module_remarks: null,
                    // site_name: null,
                    // status_code: null,
                    // site_leader: null,
                    // site_total_installation: 0,
                    // site_total_task: 0,
                    // site_completed_task: 0,
                    // site_total_document: 0,
                    // site_completed_document: 0,
                    // site_progress: 0,
                    // site_total_capacity: 0,
                    // site_completed_capacity: 0,
                    // group_engineer: {
                    // name: null,
                    // email: null,
                    // rows: [
                    //     { milestone: "Applicaton to Authorities", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Mobilization to Site", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Hoisting", startDate: "", endDate: "", remarks: "" },
                    //     // MAKE DYNAMIC BASED ON API CALL
                    // ],
                    milestone_template_id: 'x',
                },
                site_status: [],
                contractors: [],
                module_brands: [],
                milestone_template: [],
                site_attachment: {
                    file: null,
                },
                cif_file: null,
                tempData: null,
                converted_temp_data: null,
                sheets: [
                    // {
                    //     name: "Stage_1 Technical Info (ENG)",
                    // },
                    // {
                    //     name: "Stage_1 Project Info (BD)",
                    // },
                ],
                selected_sheet: 'x',
                selected_range: '',
                postResponse: null,
                site_attachments: [],
                additional_model: {
                    project_site_additional_info_id: null,
                    site_id: null,
                    ntp_applicable: 0,
                    ntp_issued: 0,
                    ntp_issue_date: null,
                    internal_project_handover_completed: 0,
                    internal_project_handover_date: null,
                    kick_off_meeting_completed: 0,
                    kick_off_meeting_date: null,
                    roof_handover_completed: 0,
                    roof_handover_date: null,
                    cops_tnc_checked: 0,
                    cops_tnc_issue: 0,
                    cops_tnc_remarks: null,
                    onm_handover_checked: 0,
                    onm_handover_issue: 0,
                    onm_handover_remarks: null,
                    turn_on_checking_approved: 0,
                    turn_on_checking_approval_date: null,
                    turn_on_checking_approver: null,
                    cif_technical_info: null,
                    cif_project_info: null,
                },
                site_milestones: [],
                series: [
                    {
                        name: 'Planned',
                        data: [
                            {
                                x: 'Application to Authorities',
                                y: [
                                    new Date('2019-03-05').getTime(),
                                    new Date('2019-03-08').getTime()
                                ]
                            },
                            {
                                x: 'Mobilization to Site',
                                y: [
                                    new Date('2019-03-08').getTime(),
                                    new Date('2019-03-11').getTime()
                                ]
                            },
                            {
                                x: 'Hoisting',
                                y: [
                                    new Date('2019-03-11').getTime(),
                                    new Date('2019-03-16').getTime()
                                ]
                            }
                        ]
                    },
                    {
                        name: 'Actual',
                        data: [
                            {
                                x: 'Application to Authorities',
                                y: [
                                    new Date('2019-03-02').getTime(),
                                    new Date('2019-03-05').getTime()
                                ]
                            },
                            {
                                x: 'Mobilization to Site',
                                y: [
                                    new Date('2019-03-06').getTime(),
                                    new Date('2019-03-09').getTime()
                                ]
                            },
                            {
                                x: 'Hoisting',
                                y: [
                                    new Date('2019-03-10').getTime(),
                                    new Date('2019-03-19').getTime()
                                ]
                            }
                        ]
                    }
                ],
                site_milestones: [],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'rangeBar',
                        background: "#DDEED2",
                    },
                    // colors: ["#2E75B5", "#00B050"], // #FF0000
                    colors: [
                        "#2E75B5",
                        ({ value, dataPointIndex }) => {
                            if (this.site_milestones.length > 0 ) {
                                if (this.site_milestones[dataPointIndex].planned_end_date) {
                                    const plannedEndDate = new Date(this.site_milestones[dataPointIndex].planned_end_date).getTime();
                                    console.log(plannedEndDate);
                                    if (value > plannedEndDate) {
                                        return '#FF0000'
                                    } else {
                                        return '#00B050'
                                    }
                                } else {
                                    return '#00B050'
                                }
                            } else {
                                return '#00B050'
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        //   enabled: true,
                        //   formatter: function(val) {
                        //     var a = moment(val[0])
                        //     var b = moment(val[1])
                        //     var diff = b.diff(a, 'days')
                        //     return diff + (diff > 1 ? ' days' : ' day')
                        //   }
                    },
                    fill: {
                        //   type: 'gradient',
                        //   gradient: {
                        //     shade: 'light',
                        //     type: 'vertical',
                        //     shadeIntensity: 0.25,
                        //     gradientToColors: undefined,
                        //     inverseColors: true,
                        //     opacityFrom: 1,
                        //     opacityTo: 1,
                        //     stops: [50, 0, 100, 100]
                        //   }
                    },
                    xaxis: {
                        type: 'datetime',
                        // labels: {
                        //     format: 'MMM yyyy', // Format for month labels
                        // },
                        // min: new Date('2023-01-01').getTime(),
                        // max: new Date('2023-12-31').getTime(),
                    },
                    legend: {
                        position: 'top',
                        markers: {
                            fillColors: ["#2E75B5", "#00B050"],
                        },
                    }
                },
                isSiteMilestoneVisible: false,
                selectedDataPointIndex: 0,
                actual_total_day : 0,
                contacts_model: {
                    project_manager: null,
                    project_engineer: null,
                    project_business_development: null,
                    project_safety: null,
                },
                projects_model: {
                    project_ppa_start_date: null,
                    project_ppa_approved_capacity: null,
                },
            };
        },
        filters: {
            formatDate(value) {
                if (value) {
                    const dateParts = value.split("-");
                    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    return formattedDate;
                }
                return "N/A"; // Return an empty string if the date is not available or invalid
            },
        },
        watch: {
            'additional_model.ntp_applicable': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issued = "0";
                    this.additional_model.ntp_issue_date = null;
                }
            },
            'additional_model.ntp_issued': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issue_date = null;
                } else if (newVal === "1") {
                    this.additional_model.ntp_issue_date = this.currentDate;
                }
            },
            'additional_model.internal_project_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.internal_project_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.internal_project_handover_date = this.currentDate;
                }
            },
            'additional_model.kick_off_meeting_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.kick_off_meeting_date = null;
                } else if (newVal === "1") {
                    this.additional_model.kick_off_meeting_date = this.currentDate;
                }
            },
            'additional_model.roof_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.roof_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.roof_handover_date = this.currentDate;
                }
            },
            'additional_model.cops_tnc_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.cops_tnc_issue = "0";
                    this.additional_model.cops_tnc_remarks = null;
                }
            },
            'additional_model.onm_handover_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.onm_handover_issue = "0";
                    this.additional_model.onm_handover_remarks = null;
                }
            },
        },
        computed:{

        },
        methods: {
            redirectToProjectDashboard(){
                this.$router.push({path: '/projectDashboardV2/' + this.model.project_id})
            },
            scrollToSite() {
                const siteSection = document.getElementById("site-profile");
                siteSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToIfaIfcModule() {
                const ifaIfacModuleSection = document.getElementById("ifc-ifa-module");
                ifaIfacModuleSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToProjectPlanning() {
                const projectPlanningSection = document.getElementById("project-planning");
                projectPlanningSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToGanttChart() {
                const ganttChartSection = document.getElementById("gantt-chart");
                ganttChartSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToTurnOnPlanning() {
                const turnOnPlanningSection = document.getElementById("turn-on-checking");
                turnOnPlanningSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToUploadCif() {
                const uploadCifSection = document.getElementById("upload-cif");
                uploadCifSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToDisplayCif() {
                const displayCifSection = document.getElementById("display-cif");
                displayCifSection.scrollIntoView({ behavior: "smooth" });
            },
            getProjectSiteStatusListing() {
                // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.site_status = response.data.data;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
            },
            updateAvailableProjectSiteStatus() {
                // set all to disabled initially, slowly allow selection based on validation
                this.site_status = this.site_status.map(item => ({
                    ...item,
                    disabled: true
                }));

                const objIndex = this.site_status.findIndex((obj => obj.status_sequence == 1));
                this.site_status[objIndex].disabled = false;

                const conditions = [
                    {
                        condition: (this.projects_model.project_ppa_start_date != null
                            && this.projects_model.project_ppa_approved_capacity != null
                            && (this.model.contractor_id != null && this.model.contractor_id != "x")),
                        statusSequence: 2
                    },
                    {
                        condition: ((this.model.site_module_brand_id != null && this.model.site_module_brand_id != "x")
                            && (this.model.site_module_quantity != null && this.model.site_module_quantity != 0)),
                        statusSequence: 3
                    },
                    {
                        condition: true,
                        statusSequence: 4
                    },
                    {
                        condition: (this.site_milestones.length > 0
                            && this.site_milestones.every(obj => obj.planned_start_date !== null && obj.planned_end_date !== null)),
                        statusSequence: 5
                    },
                    {
                        condition: this.getSiteMilestoneActualDates("Installation"),
                        statusSequence: 6
                    },
                    {
                        condition: true,
                        statusSequence: 7
                    },
                    {
                        condition: true,
                        statusSequence: 8
                    },
                    {
                        condition: this.getSiteMilestoneActualDates("Turn On"),
                        statusSequence: 9
                    },
                    {
                        condition: true, // TO BE DONE, HOW TO CHECK IF ALL FIELDS ARE FILLED?
                        statusSequence: 10
                    },
                ];

                conditions.every(({ condition, statusSequence }) => {
                    if (!condition) {
                        return false; // Exit the loop if the condition is false
                    }

                    const objIndex = this.site_status.findIndex(obj => obj.status_sequence === statusSequence);
                    this.site_status[objIndex].disabled = false;

                    return true; // Continue to the next iteration if the condition is true
                });
            },
            getSiteMilestoneActualDates(milestone_name) {
                if (this.site_milestones.length == 0) {
                    return false;
                }

                const site_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === milestone_name);

                if (typeof site_milestone_data !== "undefined") {
                    if (site_milestone_data.actual_start_date != null && site_milestone_data.actual_end_date != null) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }

            },
            getContractorListing() {
                axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.contractors = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                    } );
            },
            getModuleBrandListing(){
                axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.module_brands = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Module Brand Information");
                    });
            },
            updateDrawingWattClass() {
                const module_brand_data = this.module_brands.find(obj => obj.module_brand_id === this.model.site_drawing_module_brand_id);
                this.model.site_drawing_module_capacity = parseFloat(module_brand_data.watt_class.replace(/,/g, ""));;
                this.calculateDrawingCapacity();
            },
            calculateDrawingCapacity() {
                this.model.site_drawing_total_capacity = ((this.model.site_drawing_module_quantity * this.model.site_drawing_module_capacity) / 1000)
            },
            updateModuleAllocationWattClass() {
                const module_brand_data = this.module_brands.find(obj => obj.module_brand_id === this.model.site_module_brand_id);
                this.model.site_module_capacity = module_brand_data.watt_class;
                this.calculateModuleAllocationCapacity();
            },
            calculateModuleAllocationCapacity() {
                this.model.site_module_allocation_total_capacity = ((this.model.site_module_quantity * this.model.site_module_capacity) / 1000);
            },
            getMilestoneTemplateListing() {
                axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.milestone_template = response.data.data;
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Milestone Template Information")
                    } );
            },
            checkMilestoneDetail()
            {
                if (this.model.milestone_template_id == 'x')
                {
                    this.$notify({
                        message:
                            '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template before dive into the Details',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                }
                else
                {
                    window.open('/templates/milestoneTemplateDetailListing/' + this.model.milestone_template_id, '_blank');
                }
            },
            importSiteMilestones() {
                if (this.model.milestone_template_id == 'x')
                {
                    this.$notify({
                        message:
                            '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                }
                else
                {
                    // project_site.import_site_milestones/{site_id}
                    // call sp
                    const temp_model = {
                        milestone_template_id: this.model.milestone_template_id
                    };

                    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                    const headers = {
                        Authorization: "Bearer " + tokenData.access_token,
                    };

                    axios
                        .post(
                            apiDomain + "project/project_site.import_site_milestones/" + this.model.site_id,
                            temp_model,
                            { headers }
                        )
                        .then((response) => {
                            console.log(response);
                            this.getProjectSiteMilestoneData();
                        })
                        .catch((error) => {
                            this.errorFunction(error, "Import Site Milestones");
                        });
                }
            },
            getDashboardData() {
                this.model.site_id = this.$route.params.id;
                axios
                    .get(apiDomain + "project/project_site/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.model.project_id = response.data.data[0].project.project_id;
                            this.model.group_id = response.data.data[0].group.group_id;
                            this.model.site_name = response.data.data[0].site_name;
                            this.model.status_id = response.data.data[0].status.status_id;
                            this.model.site_address = response.data.data[0].site_address;
                            this.model.site_postal_code = response.data.data[0].site_postal_code;
                            this.model.site_code = response.data.data[0].site_code;
                            this.model.site_cod_date = response.data.data[0].site_cod_date;
                            this.getProjectData();
                            this.model.site_target_turn_on_date = response.data.data[0].site_target_turn_on_date;
                            this.model.site_actual_turn_on_date = response.data.data[0].site_actual_turn_on_date;
                            // this.model.site_total_capacity = response.data.data[0].site_total_capacity;
                            this.model.site_total_capacity = parseFloat(response.data.data[0].site_total_capacity.replace(/,/g, ""));

                            this.model.site_drawing_module_brand_id = response.data.data[0].site_drawing_module_brand.module_brand_id;
                            this.model.site_drawing_module_quantity = parseFloat(response.data.data[0].site_drawing_module_quantity.replace(/,/g, ""));
                            this.model.site_drawing_module_capacity = parseFloat(response.data.data[0].site_drawing_module_capacity.replace(/,/g, ""));
                            this.model.site_drawing_total_capacity = parseFloat(response.data.data[0].site_drawing_total_capacity.replace(/,/g, ""));
                            this.model.site_drawing_ifa_date = response.data.data[0].site_drawing_ifa_date;
                            this.model.site_drawing_ifc_date = response.data.data[0].site_drawing_ifc_date;
                            this.model.site_module_brand_id = response.data.data[0].site_module_brand.module_brand_id;
                            this.model.site_module_quantity = parseFloat(response.data.data[0].site_module_quantity.replace(/,/g, ""));
                            this.model.site_module_capacity = parseFloat(response.data.data[0].site_module_capacity.replace(/,/g, ""));
                            this.model.site_module_allocation_total_capacity = parseFloat(response.data.data[0].site_module_allocation_total_capacity.replace(/,/g, ""));
                            this.model.site_ifc_ifa_module_remarks = response.data.data[0].site_ifc_ifa_module_remarks;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Information");
                    });
            },
            getProjectData() {
                axios
                    .get(apiDomain + "project/project/" + this.model.project_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.contacts_model.project_manager = response.data.data[0].project_manager.name;
                            this.contacts_model.project_engineer = response.data.data[0].project_engineer.name;
                            this.contacts_model.project_business_development = response.data.data[0].project_business_development.name;
                            this.contacts_model.project_safety = response.data.data[0].project_safety.name;

                            this.projects_model.project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            this.projects_model.project_ppa_approved_capacity = response.data.data[0].project_ppa_approved_capacity;
                            // this.updateAvailableProjectSiteStatus();

                            // default cod date = ppa signed date + 180 days
                            const project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            console.log('this.model.site_cod_date');
                            console.log(this.model.site_cod_date);
                            if (project_ppa_start_date && this.model.site_cod_date == null) {
                                const ppaCod = new Date(project_ppa_start_date);
                                ppaCod.setDate(ppaCod.getDate() + 180);
                                const ppaCodMonth = String((ppaCod.getMonth()+1)).padStart(2, '0');
                                const ppaCodDay = String(ppaCod.getDate()).padStart(2, '0');
                                const ppaCodDate = ppaCod.getFullYear() + '-' + ppaCodMonth + '-' + ppaCodDay;
                                this.model.site_cod_date = ppaCodDate;
                            }

                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch((error) => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Project Information");
                        }
                    });
            },
            getDashboardAdditionalInfo() {
                axios
                    .get(apiDomain + "project/project_site_additional_info/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.additional_model.project_site_additional_info_id = response.data.data[0].project_site_additional_info_id;
                            this.additional_model.site_id = response.data.data[0].site_id;
                            this.additional_model.ntp_applicable = response.data.data[0].ntp_applicable;
                            this.additional_model.ntp_issued = response.data.data[0].ntp_issued;
                            this.additional_model.ntp_issue_date = response.data.data[0].ntp_issue_date;
                            this.additional_model.internal_project_handover_completed = response.data.data[0].internal_project_handover_completed;
                            this.additional_model.internal_project_handover_date = response.data.data[0].internal_project_handover_date;
                            this.additional_model.kick_off_meeting_completed = response.data.data[0].kick_off_meeting_completed;
                            this.additional_model.kick_off_meeting_date = response.data.data[0].kick_off_meeting_date;
                            this.additional_model.roof_handover_completed = response.data.data[0].roof_handover_completed;
                            this.additional_model.roof_handover_date = response.data.data[0].roof_handover_date;
                            this.additional_model.cops_tnc_checked = response.data.data[0].cops_tnc_checked;
                            this.additional_model.cops_tnc_issue = response.data.data[0].cops_tnc_issue;
                            this.additional_model.cops_tnc_remarks = response.data.data[0].cops_tnc_remarks;
                            this.additional_model.onm_handover_checked = response.data.data[0].onm_handover_checked;
                            this.additional_model.onm_handover_issue = response.data.data[0].onm_handover_issue;
                            this.additional_model.onm_handover_remarks = response.data.data[0].onm_handover_remarks;
                            this.additional_model.turn_on_checking_approved = response.data.data[0].turn_on_checking_approved;
                            this.additional_model.turn_on_checking_approval_date = response.data.data[0].turn_on_checking_approval_date;
                            this.additional_model.turn_on_checking_approver = response.data.data[0].turn_on_checking_approver;
                            this.additional_model.cif_technical_info = JSON.parse(response.data.data[0].cif_technical_info);
                            this.additional_model.cif_project_info = JSON.parse(response.data.data[0].cif_project_info);
                            if (this.additional_model.cif_project_info) {
                                this.addIsEmailAttribute(this.additional_model.cif_project_info);
                            }
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Additional Information");
                    });
            },
            addIsEmailAttribute(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].column_4 === 'Email / Phone Number') {
                        for (let j = i + 1; j <= i + 4 && j < data.length; j++) {
                            data[j].isEmail = true;
                        }
                    }
                }
            },
            getProjectSiteMilestoneData() {
                axios
                    .get(
                        apiDomain +
                        "project/project_site_milestone.show_by_site/" +
                        this.model.site_id,
                        { headers: getHeader() }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            let data = response.data.data;
                            data.forEach(item => {
                                item.total_planned_day = 0;
                                item.total_actual_day = 0;
                                if(item.planned_start_date !== null && item.planned_end_date !== null){
                                    item.total_planned_day  = this.getDaysDifference(item.planned_start_date,item.planned_end_date)
                                }

                                if(item.actual_start_date !== null && item.actual_end_date !== null){
                                    item.total_actual_day  = this.getDaysDifference(item.actual_start_date,item.actual_end_date)
                                }
                            });
                            this.site_milestones = data;
                            this.updateGanttChartSeries();

                            // get and update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                            // get and update Actual Turn On based on "Turn On" actual_end_date if value is null
                            // ONE WAY!!!
                            // changing planned_end_date of "Turn On" will change site_target_turn_on_date
                            // changing site_target_turn_on_date will not change planned_end_date of "Turn On"

                            const turn_on_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === "Turn On");

                            if (turn_on_milestone_data != undefined) {
                                // update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                                if (turn_on_milestone_data.planned_end_date) {
                                    this.model.site_target_turn_on_date = turn_on_milestone_data.planned_end_date;
                                }
                                // update Actual Turn On based on "Turn On" actual_end_date if value is null
                                if (turn_on_milestone_data.actual_end_date && (this.model.site_actual_turn_on_date == null)) {
                                    this.model.site_actual_turn_on_date = turn_on_milestone_data.actual_end_date;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                    });
            },
            updateGanttChartSeries() {
                const temp_series = [
                    {
                        name: 'Planned',
                        data: this.site_milestones.map(item => ({
                            x: item.site_milestone_code,
                            y: [
                                item.planned_start_date ? new Date(item.planned_start_date).getTime() : null,
                                item.planned_end_date ? new Date(item.planned_end_date).getTime() : null
                            ]
                        }))
                    },
                    {
                        name: 'Actual',
                        data: this.site_milestones.map(item => ({
                            x: item.site_milestone_code,
                            y: [
                                item.actual_start_date ? new Date(item.actual_start_date).getTime() : null,
                                item.actual_end_date ? new Date(item.actual_end_date).getTime() : null
                            ]
                        }))
                    }
                ];

                this.series = [];
                this.series = temp_series;

                // this.chartOptions.xaxis.min = new Date('2023-07-01').getTime();
                // this.chartOptions.xaxis.max = new Date('2023-12-01').getTime();
            },
            getContractorFromSite()
            {
                axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id + '?client=mobile', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.model.contractor_id = response.data.data[0].contractor.contractor_id;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Contractor Information")
                        }
                    } );
            },
            saveSiteData() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put

                axios.put(apiDomain + 'project/project_site/' + this.model.site_id, this.model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            // this.$router.back()
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Information")
                    });
            },
            setActiveSiteContractor() {
                if ( this.model.contractor_id == 'x' ) {
                    this.$notify({
                        message:
                            '<b>Invalid Contractor : </b> - Please Select A Contractor to proceed.',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                } else {
                    const site_contractor_model = {
                        site_id: this.model.site_id,
                        contractor_id: this.model.contractor_id,
                        active_status: 1
                    };

                    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                    const headers = {
                        Authorization: "Bearer " + tokenData.access_token,
                    };

                    axios
                        .post(
                            apiDomain + "project/site_contractor.set_active_contractor/" + this.model.site_id,
                            site_contractor_model,
                            { headers }
                        )
                        .then((response) => {
                            this.$notify({
                                message:
                                    '<b>Site Contractor Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        })
                        .catch((error) => {
                            this.errorFunction(error, "Set Active Site Contractor")
                        });
                }
            },
            saveSiteAdditionalInfo() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put
                axios.put(apiDomain + 'project/project_site_additional_info/' + this.model.site_id, this.additional_model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.getDashboardAdditionalInfo();
                            // this.$router.back()
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Additional Information")
                    });

            },
            approveToCloseTurnOnChecking()
            {
                const dummy_model = {
                    dummy_data: 0
                };

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.approve_to_close/" + this.model.site_id,
                        dummy_model,
                        { headers }
                    )
                    .then((response) => {
                        this.$notify({
                            message:
                                '<b>Turn On Checking Approval To Close Successfully Approved.</b>',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                        //   this.$router.back()
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Approve To Close Turn On Checking")
                    });
            },
            saveSiteMilestoneData() {
                // this.site_milestones

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_milestone.update_site_milestones/" + this.model.site_id,
                        this.site_milestones,
                        { headers }
                    )
                    .then((response) => {
                        this.getProjectSiteMilestoneData();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Update Site Milestones Attachment");
                    });

            },
            dataPointSelectionHandler(e, chartContext, config) {
                //   this.tempPieDataCNI = [config.seriesIndex, config.dataPointIndex];
                this.selectedDataPointIndex = config.dataPointIndex;
                this.total_actual_date = this.site_milestones[config.dataPointIndex].total_actual_day;
                this.isSiteMilestoneVisible = true;
            },
            closeAndUpdateGanttChart() {
                this.updateGanttChartSeries();
                this.saveSiteMilestoneData();
                this.isSiteMilestoneVisible = false;
            },
            getSiteAttachmentListing() {
                axios
                    .get(apiDomain + "project/project_site.site_attachment/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.site_attachments = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Attachment Listing");
                    });
            },
            onChange(event) {
                this.site_attachment.file = event.target.files ? event.target.files[0] : null;
            },
            uploadFile() {
                let formData = jsonFormData(this.site_attachment);

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site.upload_site_attachment/" + this.model.site_id,
                        formData,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.getSiteAttachmentListing();
                        this.site_attachment.file = null;
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload Site Attachment");
                    });
            },
            downloadFile(apiEndpoint, filename) {
                axios.get(apiDomain + apiEndpoint, { headers: getHeader(),  responseType: 'blob'})
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', filename);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    });
            },
            deleteFile(site_file_id) {
                axios.get(apiDomain  + "project/project_site.delete_site_attachment/" + site_file_id, { headers: getHeader()})
                    .then((response) => {
                        this.getSiteAttachmentListing();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Delete Site Attachment");
                    });
            },
            onChangeCif(event) {
                this.cif_file = event.target.files ? event.target.files[0] : null;
            },
            loadSheetNames() {
                if (typeof(this.$refs.excelRead._workbook) !== "undefined") {
                    // this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets;
                    this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets.filter(item => item.name == "Stage_1 Technical Info (ENG)" || item.name == "Stage_1 Project Info (BD)");
                }
            },
            updateRangeToSelect () {
                if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.selected_range = 'B8:D63';
                } else if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.selected_range = 'B7:E65';
                }

            },
            showUploadingData () {
                this.tempData = this.$refs.excelJson.$data.collection;
                if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.converted_temp_data = this.$refs.excelJson.$data.collection.map((item) => {
                        return {
                            column_name: item.__EMPTY,
                            unit: item.__EMPTY_1,
                            value: item["Building 1"],
                        };
                    });
                } else if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.converted_temp_data = this.$refs.excelJson.$data.collection.map((item) => {
                        return {
                            column_1: item.Information,
                            column_2: item.Response,
                            column_3: item.__EMPTY,
                            column_4: item.Remarks,
                        };
                    });
                }
            },
            uploadCif() {
                this.updateRangeToSelect();
                this.showUploadingData();
                if (this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.uploadCifTechnicalInfo();
                } else if (this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.uploadCifProjectInfo();
                }
            },
            uploadCifTechnicalInfo () {
                const post_data = {
                    data: this.converted_temp_data
                }

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.cif_technical_info/" + this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.selected_sheet = 'x';
                        this.selected_range = '';
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload CIF Technical Info");
                    });
            },
            uploadCifProjectInfo () {
                const post_data = {
                    data: this.converted_temp_data
                }

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.cif_project_info/" + this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.selected_sheet = 'x';
                        this.selected_range = '';
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload CIF Project Info");
                    });
            },
            getDaysDifference(startDate, endDate) {

                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){

                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                console.log(plannedStartDate)
                console.log(plannedEndDate)
                console.log(totalPlannedDate)

                console.log(actualStartDate)
                console.log(actualEndDate)
                console.log(totalActualDate)

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }

            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            convertDateTime(date){
                let convertDate = new Date(date);
                const year = convertDate.getFullYear();
                const month = convertDate.getMonth();
                const day = convertDate.getDate();

                return convertDate.toISOString().slice(0,10);
            }

        }
    };
</script>
<style>

    .bg-custom {
        background-color: #EFF7EA;
    }

    .card-border-diagonal {
        /* border: 2px solid #BDDBA9; */
        border-radius: 100px 0 100px 0;
        /* margin: 20px; */
        padding: 10px;
        /* max-width: 300px; */
    }

    .card-border-inner {
        border: 1px solid #ADB5BD;
        border-radius: 50px 50px 50px 50px;
        /* margin: 20px; */
        padding: 10px;
        /* max-width: 300px; */
    }

    .form-custom-css .form-control:disabled {
        border: none !important;
        font-weight: bold;
        background-color: transparent !important;
        appearance: none !important;
        -webkit-appearance: none;
        background-image: none !important;
    }

    .form-custom-css .form-control {
        border: 2px solid #BDDBA9 !important;
        border-radius: 0 !important;
        color: black !important;
    }

    .form-custom-css .form-control-label {
        color: #525f7f !important;
        font-size: .875rem !important;
        font-weight: 600 !important;
    }

    /* .form-custom-css-grey .form-control:disabled { */
    /* border: none !important; */
    /* font-weight: bold; */
    /* background-color: transparent !important; */
    /* appearance: none !important; */
    /* -webkit-appearance: none; */
    /* background-image: none !important; */
    /* } */

    .form-custom-css-grey .form-control {
        border: 2px solid #BDDBA9 !important;
        border-radius: 0 !important;
        color: black !important;
    }

    .form-custom-css-grey .form-control-label {
        color: #525f7f !important;
        font-size: .875rem !important;
        font-weight: 600 !important;
    }

    .form-label {
        color: #525f7f !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-bottom: 5px !important;
    }

    .form-content {
        font-size: 16px !important;
        font-weight: bold !important;
        color: black !important;
        margin-top: 5px !important;
    }

    .b-col-centered {
        display: flex;
        justify-content: center;
    }

    .scroll-follower{
        position: fixed;
        transform: translateY(20%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .scroll-follower .base-button {
        width: 100%;
        text-align: center;
    }

    .scroll-follower-right{
        position: fixed;
        width: 13%;
        transform: translateY(5%);
        /* display: flex; */
        /* flex-direction: column; */
        /* align-items: center; */
    }

    .h2-key-contacts {
        font-weight: 700 !important;
        color: black !important;
    }

    .form-label-key-contacts {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: black !important;
        margin-bottom: 5px !important;
    }

    .form-content-key-contacts {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: black !important;
        margin-top: 5px !important;
    }

    .button-css {
        border-radius: 10px;
        background: transparent;
        color: #3E5C2B;
    }

</style>
