
export default [
  {
    requestClass: 'Action Item (V2.0)',
    requestBy: 'Solar Ex',
    requestType: 'One Time Document',
    requestDate: '01-Jan-2021',
    requestSite: 'SN-NT',
  },
  {
    requestClass: 'Tool Box Meeting (V1.0)',
    requestBy: 'Solar Ex',
    requestType: 'One Time Document',
    requestDate: '01-Jan-2021',
    requestSite: 'SN-NT',
  },
  {
    requestClass: 'Site Document (V1.0)',
    requestBy: 'Long Seng',
    requestType: 'One Time Document',
    requestDate: '01-Jan-2021',
    requestSite: 'SN-NT',
  },
  {
    requestClass: 'E-Permit To Work (V1.0)',
    requestBy: 'Long Seng',
    requestType: 'One Time Document',
    requestDate: '05-Jan-2021',
    requestSite: 'SN-NT',
  },
  {
    requestClass: 'Contractor Checklist (V3.0)',
    requestBy: 'Solar Ex',
    requestType: 'One Time Document',
    requestDate: '05-Jan-2021',
    requestSite: 'SN-NT',
  },

  {
    requestClass: 'Site Document',
    requestBy: 'Long Seng',
    requestType: 'Non-Mandatory Request',
    requestDate: '01-Jan-2021',
    requestSite: 'SN-WT',
  },
  {
    requestClass: 'E-Permit To Work',
    requestBy: 'Long Seng',
    requestType: 'Non-Mandatory Request',
    requestDate: '05-Jan-2021',
    requestSite: 'SN-WT',
  },
  {
    requestClass: 'Contractor Checklist',
    requestBy: 'Solar Ex',
    requestType: 'Non-Mandatory Request',
    requestDate: '05-Jan-2021',
    requestSite: 'SN-WT',
  },


  {
    requestClass: 'Daily Manpower Update',
    requestBy: 'Solar Ex',
    requestType: 'Recurring Document',
    requestDate: '01-Jan-2021',
    requestSite: 'SN-NT',
  },
  {
    requestClass: 'Daily PTW',
    requestBy: 'Solar Ex',
    requestType: 'Recurring Document',
    requestDate: '01-Jan-2021',
    requestSite: 'SN-NT',
  },
  {
    requestClass: 'Daily Safety Checklist',
    requestBy: 'Long Seng',
    requestType: 'Recurring Document',
    requestDate: '01-Jan-2021',
    requestSite: 'SN-NT',
  },



]
