<template>
  <card>
    <h3 slot="header" class="mb-0">Edit Milestone Template for ({{ model.milestone_template_name }}) </h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Template Name"
                      name="Template Name"
                      placeholder="Template Name"
                      v-model="model.milestone_template_name">
          </base-input>
        </b-col>
      </div>

      <base-button type="primary" @click="updateDate()" >Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      this.model.milestone_template_id = this.$route.params.id

      axios.get(apiDomain + 'template/milestone_template/'+this.model.milestone_template_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.model.milestone_template_id = response.data.data[0].milestone_template_id
            this.model.milestone_template_name = response.data.data[0].milestone_template_name
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, 'Edit Milestone Template')
      } );
    },
    data() {
      return {
        model: {
          milestone_template_name: null,
          milestone_template_id: null,
        }
      }
    },
    methods: {
      updateDate()
      {
        if ( !this.model.milestone_template_name )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Milestone Template</b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'warning',
          });
        }
        else
        {
          axios.put(apiDomain + 'template/milestone_template/'+this.model.milestone_template_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
              message:
                '<b>Milestone Template : ' + this.model.milestone_template_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, 'Update Milestone Template')
          } );
        }
      },
    }
  }
</script>
<style>
</style>
