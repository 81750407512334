import { render, staticRenderFns } from "./AuditLogDocumentListing.vue?vue&type=template&id=22756d82&"
import script from "./AuditLogDocumentListing.vue?vue&type=script&lang=js&"
export * from "./AuditLogDocumentListing.vue?vue&type=script&lang=js&"
import style0 from "./AuditLogDocumentListing.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports