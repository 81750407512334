<template>
  <div class="bg-transparent">
    <base-header class="pb-6 bg-default shadow">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">
            Consolidated Dashboard
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right"> </b-col>
      </b-row>
      <!-- Card stats -->
    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6 bg-default shadow">
      <card class="bg-default shadow; border border-white;">
        <form class="needs-validation" @submit.prevent="submit">
          <div class="form-row" style="color: white">
            <b-col md="6">
              <label
                ><b>To Date : {{ model.end_date }}</b></label
              >
              <base-input
                name="To"
                type="date"
                v-model="model.end_date"
                @change="changeAllChart()"
              >
              </base-input>
            </b-col>

            <b-col md="6">
              <label><b>Type of Project </b></label>
              <base-input
                name="Type_Of_Project"
                required
                v-model="model.project_type_id"
              >
                <select
                  class="form-control"
                  v-model="model.project_type_id"
                  @change="changeAllChart()"
                >
                  <!-- <option Selected disabled value="x">-- Please Select A Project Type --</option> -->
                  <option value="0">-- CONSOLIDATED --</option>
                  <option
                    v-for="item in project_types"
                    :key="item.project_type_id"
                    v-bind:value="item.project_type_id"
                  >
                    -- {{ item.project_type }} --
                  </option>
                </select>
              </base-input>
            </b-col>

            <!-- <b-col md="2">
          <br>
          <base-button size="m" type="primary">View Consolidated Project</base-button>
        </b-col> -->

            <!-- <b-col md="1">
            <label><b>Type of Projects</b></label>
        </b-col>

        <b-col md="3">
          <b-form-radio-group v-model="model.radios.radio1" @change="changetitle()">
              <b-form-radio value="CONSOLIDATED" name="consolidated" class="mb-3">
                <b>Consolidated</b>
              </b-form-radio>
              <b-form-radio value="C & I" name="CNI" class="mb-3">
                <b>C & I Project</b>
              </b-form-radio>
              <b-form-radio value="SN2" name="SN2" class="mb-3">
                <b>SN2 Project</b>
              </b-form-radio>
              <b-form-radio value="SN3" name="SN3" class="mb-3">
                <b>SN3 Project</b>
              </b-form-radio>
          </b-form-radio-group>
        </b-col> -->
          </div>

          <!-- <div class="form-row" style="color: white">
            <b-col md="2">
              <label><b>Top OverDue</b></label>
            </b-col>
            <b-col md="10">
              <b-form-radio-group
                v-model="model.radios.radio2"
                @change="changetitle()"
              >
                <b-form-radio value="HIDE" name="HIDE" class="mb-3">
                  <b>Hide</b>
                </b-form-radio>
                <b-form-radio value="PROJECT" name="PROJECT">
                  <b>Project</b>
                </b-form-radio>
                <b-form-radio value="DOCUMENT" name="DOCUMENT">
                  <b>Document</b>
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </div> -->
        </form>
      </card>

      <!-- <card style="background-color: #0c765a;"> -->
      <card
        v-if="model.radios.radio2 == 'PROJECT'"
        class="bg-default shadow; border border-white"
      >
        <H2 class="text-center text-white"
          ><b>Top 5 Overdue Project Site</b></H2
        >
        <br />
        <b-row>
          <b-col md="1"> </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_project.top_1_site }} with <br /><b
                    >{{ top_overdue_project.top_1_mwp }} MWp</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_project.top_2_site }} with <br /><b
                    >{{ top_overdue_project.top_2_mwp }} MWp</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_project.top_3_site }} with <br /><b
                    >{{ top_overdue_project.top_3_mwp }} MWp</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_project.top_4_site }} with <br /><b
                    >{{ top_overdue_project.top_4_mwp }} MWp</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_project.top_5_site }} with <br /><b
                    >{{ top_overdue_project.top_5_mwp }} MWp</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="1"> </b-col>
        </b-row>
      </card>

      <card
        v-if="
          model.title == 'CONSOLIDATED' && model.radios.radio2 == 'DOCUMENT'
        "
        class="bg-default shadow; border border-white"
      >
        <H2 class="text-center text-white"
          ><b>Top 5 Highest Overdue Project Site Document</b></H2
        >
        <br />
        <b-row>
          <b-col md="1"> </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_document.top_1_site }} with <br /><b
                    >{{ top_overdue_document.top_1_document }} Documents</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_document.top_2_site }} with <br /><b
                    >{{ top_overdue_document.top_2_document }} Documents</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_document.top_3_site }} with <br /><b
                    >{{ top_overdue_document.top_3_document }} Documents</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_document.top_4_site }} with <br /><b
                    >{{ top_overdue_document.top_4_document }} Documents</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0">
              <b-card-body>
                <b-card-title
                  title-tag="h5"
                  class="text-uppercase text-muted mb-0 text-white text-center"
                  >{{ top_overdue_document.top_5_site }} with <br /><b
                    >{{ top_overdue_document.top_5_document }} Documents</b
                  ></b-card-title
                >
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="1"> </b-col>
        </b-row>
      </card>

      <b-row>
        <b-col
          xl="4"
          v-if="model.project_type_id != 0 && model.project_type_id != 1"
        >
          <card
            type="default"
            header-classes="bg-transparent"
            class="border border-white;"
          >
            <b-row align-v="center" slot="header">
              &nbsp;&nbsp;
              <h5 class="h3 mb-0 text-white">PROJECT MILESTONE SUMMARY</h5>
            </b-row>
            <b-row>
              <b-col xl="8">
                <apexchart
                  type="donut"
                  width="100%"
                  height="300px"
                  :options="ChartOptionsProjectMilestoneSummary"
                  :series="seriesProjectMilestoneSummary"
                ></apexchart>
              </b-col>
              <b-col xl="4">
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b>Pending Milestone</b><br />
                    <h1 style="color: white">
                      {{ project_milestone_summary.total_pending }}
                    </h1>
                  </div>
                </b-row>
                <br />
                <br />
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b>Total Turn-On</b><br />
                    <h1 style="color: white">
                      {{ project_milestone_summary.total_turn_on }}
                    </h1>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </card>
        </b-col>
        <b-col xl="4">
          <card
            type="default"
            header-classes="bg-transparent"
            class="border border-white;"
            v-if="false"
          >
            <!-- v-if="model.project_type_id != 1" -->
            <b-row align-v="center" slot="header">
              &nbsp;&nbsp;
              <h5 class="h3 mb-0 text-white">TURN ON (MWP)</h5>
            </b-row>
            <b-row>
              <b-col xl="8">
                <apexchart
                  type="donut"
                  width="100%"
                  height="300px"
                  :options="ChartOptionsProjectTurnOn"
                  :series="seriesProjectTurnOn"
                ></apexchart>
              </b-col>
              <b-col xl="4">
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b>Total Est (MWp)</b><br />
                    <h1 style="color: white">
                      {{ project_turn_on.total_est }}
                    </h1>
                  </div>
                </b-row>
                <br />
                <br />
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b>Total Turn On (MWp)</b><br />
                    <h1 style="color: white">
                      {{ project_turn_on.total_turn_on }}
                    </h1>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </card>
        </b-col>
        <b-col
          xl="4"
          v-if="model.project_type_id != 0 && model.project_type_id != 1"
        >
          <card
            type="default"
            header-classes="bg-transparent"
            class="border border-white;"
          >
            <b-row align-v="center" slot="header">
              &nbsp;&nbsp;
              <h5 class="h3 mb-0 text-white">DOCUMENT SUMMARY</h5>
            </b-row>
            <b-row>
              <b-col xl="8">
                <apexchart
                  type="donut"
                  width="100%"
                  height="299px"
                  :options="ChartOptionsDocumentSummary"
                  :series="seriesDocumentSummary"
                ></apexchart>
              </b-col>
              <b-col xl="4">
                <b-row>
                  <div style="color: white; font-size: 11px">
                    <b>Total No of Document</b><br />
                    <h1 style="color: white">
                      {{ document_summary.document_total }}
                    </h1>
                  </div>
                </b-row>
                <br />
                <br />
                <b-row>
                  <div style="color: white; font-size: 11px">
                    <b>Total Completed Document</b><br />
                    <h1 style="color: white">
                      {{ document_summary.document_completed }}
                    </h1>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </card>
        </b-col>
        <b-col xl="4">
          <div @click="popUp()">
            <card
              type="default"
              header-classes="bg-transparent"
              class="border border-white;"
              v-if="false"
            >
              <!-- v-if="model.project_type_id != 1" -->
              <b-row align-v="center" slot="header">
                &nbsp;&nbsp;
                <h5 class="h3 mb-0 text-white">OVERDUE PROJECT</h5>
              </b-row>
              <b-row>
                <b-col xl="8">
                  <apexchart
                    type="donut"
                    width="100%"
                    height="300px"
                    :options="ChartOptionsProjectOverdue"
                    :series="seriesProjectOverdue"
                  ></apexchart>
                </b-col>
                <b-col xl="4">
                  <b-row>
                    <div style="color: white; font-size: 12px">
                      <b>Total Overdue Site</b><br />
                      <h1 style="color: white">
                        {{ model.project_overdue_total_site }}
                      </h1>
                    </div>
                  </b-row>
                  <br />
                  <br />
                  <b-row>
                    <div style="color: white; font-size: 12px">
                      <b
                        >Highest Overdue : {{ overdue_highest.high_message }}</b
                      >
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </card>
          </div>
        </b-col>
        <b-col xl="4">
          <card
            type="default"
            header-classes="bg-transparent"
            class="border border-white;"
            v-if="false"
          >
            <!-- v-if="model.project_type_id != 1" -->
            <b-row align-v="center" slot="header">
              &nbsp;&nbsp;
              <h5 class="h3 mb-0 text-white">OVERDUE TURN ON</h5>
            </b-row>
            <b-row>
              <b-col xl="8">
                <apexchart
                  type="donut"
                  width="100%"
                  height="300px"
                  :options="ChartOptionsOverDueTurnOn"
                  :series="seriesOverDueTurnOn"
                ></apexchart>
              </b-col>
              <b-col xl="4">
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b>Total Overdue (MWp) </b><br />
                    <h1 style="color: white">
                      {{ overdue_turn_on.overdue_total_mwp }}
                    </h1>
                  </div>
                </b-row>
                <br />
                <br />
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b>Highest Overdue : {{ overdue_turn_on.high_message }}</b>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </card>
        </b-col>
        <b-col
          xl="4"
          v-if="model.project_type_id != 0 && model.project_type_id != 1"
        >
          <card
            type="default"
            header-classes="bg-transparent"
            class="border border-white;"
          >
            <b-row align-v="center" slot="header">
              &nbsp;&nbsp;
              <h5 class="h3 mb-0 text-white">OVERDUE DOCUMENT</h5>
            </b-row>
            <b-row>
              <b-col xl="8">
                <apexchart
                  type="donut"
                  width="100%"
                  height="300px"
                  :options="ChartOptionsDocumentOverdue"
                  :series="seriesDocumentOverdue"
                ></apexchart>
              </b-col>
              <b-col xl="4">
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b>Total Overdue Document</b><br />
                    <h1 style="color: white">
                      {{ document_overdue.total_overdue }}
                    </h1>
                  </div>
                </b-row>
                <br />
                <br />
                <b-row>
                  <div style="color: white; font-size: 12px">
                    <b style="color: white"
                      >Highest Overdue : {{ document_overdue.message }}</b
                    >
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>

      <b-row
        ><b-col
          ><b-card class="bg-dark-green-bg" v-if="model.project_type_id == 0"
            ><b-card-title class="pl-4 h1 text-blue-text"
              >ONGOING PROJECTS</b-card-title
            ><b-card-body class="mt-n4"
              ><b-row
                ><b-col xl="6" @click="handleAll()" class="d-flex"
                  ><b-card class="flex-grow-1 bg-small-box-bg"
                    ><b-row
                      ><b-col cols="3"
                        ><b-card-img
                          src="/img/brand/consolidateddashboard/01_solarpanel.png"
                        ></b-card-img></b-col
                      ><b-col cols="9" class="my-auto text-center"
                        ><b-card-text class="display-1 text-white"
                          >{{
                            model_ongoing.total_ongoing_mwp
                          }}
                          MWp</b-card-text
                        ></b-col
                      ></b-row
                    ></b-card
                  ></b-col
                ><b-col xl="6" @click="handleAll()" class="d-flex"
                  ><b-card class="flex-grow-1 bg-small-box-bg"
                    ><b-row
                      ><b-col cols="3"
                        ><b-card-img
                          src="/img/brand/consolidateddashboard/01_sites.png"
                        ></b-card-img></b-col
                      ><b-col cols="9" class="my-auto text-center"
                        ><b-card-text class="display-1 text-white"
                          >{{ model_ongoing.total_ongoing_site }} sites
                        </b-card-text></b-col
                      ></b-row
                    ></b-card
                  ></b-col
                ></b-row
              ><b-row
                ><b-col xl="6" @click="handleCNI()" class="d-flex"
                  ><b-card class="flex-grow-1 bg-small-box-bg"
                    ><b-card-title class="h2 text-blue-text">C&I</b-card-title
                    ><b-row
                      ><b-col cols="2"
                        ><b-card-img
                          src="/img/brand/consolidateddashboard/01_CNI.png"
                        ></b-card-img></b-col
                      ><b-col cols="10" class="my-auto text-center"
                        ><b-card-text class="display-3 text-white"
                          >{{ model_ongoing.cni_ongoing_mwp }} MWp -
                          {{ model_ongoing.cni_ongoing_site }}
                          sites</b-card-text
                        ></b-col
                      ></b-row
                    ></b-card
                  ></b-col
                ><b-col xl="6" @click="handleSN()" class="d-flex"
                  ><b-card class="flex-grow-1 bg-small-box-bg"
                    ><b-card-title class="h2 text-blue-text"
                      >SOLAR NOVA</b-card-title
                    ><b-row
                      ><b-col cols="2"
                        ><b-card-img
                          src="/img/brand/consolidateddashboard/01_Solarnova.png"
                        ></b-card-img></b-col
                      ><b-col cols="10" class="my-auto text-center"
                        ><b-card-text class="display-3 text-white"
                          >{{ model_ongoing.sn_ongoing_mwp }} MWp -
                          {{ model_ongoing.sn_ongoing_site }} sites</b-card-text
                        ></b-col
                      ></b-row
                    ></b-card
                  ></b-col
                ></b-row
              ></b-card-body
            ></b-card
          ></b-col
        ></b-row
      >

      <b-row
        ><b-col
          ><b-card class="bg-dark-green-bg" v-if="model.project_type_id == 0"
            ><b-card-body
              ><b-row
                ><b-col xl="6"
                  ><b-card class="bg-small-box-bg"
                    ><b-card-title class="text-blue-text"
                      >C&I PROJECT STATUS</b-card-title
                    ><b-card-body
                      ><b-row
                        ><b-col xl="12">
                          <!-- <div class="text-white">{{ seriesPieCNI }}</div> -->
                          <!-- <div class="text-white">{{ tempPieDataCNI }}</div> -->
                          <apexchart
                            ref="cniPieChart"
                            type="pie"
                            height="400px"
                            :options="chartOptionsPieCNI"
                            :series="seriesPieCNI"
                            @dataPointSelection="
                              dataPointSelectionHandlerPieCNI
                            "
                          ></apexchart></b-col></b-row
                      ><b-row
                        ><b-col xl="12">
                          <!-- <div class="text-white">{{ seriesBarCNI }}</div> -->
                          <!-- <div class="text-white">{{ tempBarDataCNI }}</div> -->
                          <!-- <div>{{ seriesSiteCount }}</div> -->
                          <apexchart
                            type="bar"
                            height="400px"
                            :options="chartOptionsBarCNI"
                            :series="seriesBarCNI"
                            @dataPointSelection="
                              dataPointSelectionHandlerBarCNI
                            "
                          ></apexchart> </b-col></b-row></b-card-body></b-card></b-col
                ><b-col xl="6"
                  ><b-card class="bg-small-box-bg"
                    ><b-card-title class="text-blue-text"
                      >SOLAR NOVA PROJECT STATUS</b-card-title
                    ><b-card-body
                      ><b-row
                        ><b-col xl="12">
                          <apexchart
                            type="pie"
                            height="400px"
                            :options="chartOptionsPieSN"
                            :series="seriesPieSN"
                            @dataPointSelection="dataPointSelectionHandlerPieSN"
                          ></apexchart></b-col></b-row
                      ><b-row
                        ><b-col xl="12">
                          <apexchart
                            type="bar"
                            height="400px"
                            :options="chartOptionsBarSN"
                            :series="seriesBarSN"
                            @dataPointSelection="dataPointSelectionHandlerBarSN"
                          ></apexchart> </b-col></b-row></b-card-body></b-card></b-col></b-row></b-card-body></b-card></b-col
      ></b-row>

      <!-- <b-row><b-col>START HERE</b-col></b-row> -->

      <b-row
        ><b-col
          ><b-card class="bg-dark-green-bg" v-if="model.project_type_id == 0"
            ><b-card-body
              ><b-row
                ><b-col xl="12"
                  ><b-card class="bg-small-box-bg"
                    ><b-card-title class="text-blue-text"
                      >MONTHLY TURN ON</b-card-title
                    ><b-card-body
                      ><b-row
                        ><b-col xl="12">
                          <apexchart
                            type="bar"
                            height="350"
                            :options="chartOptionsMonthlyTurnOn"
                            :series="seriesMonthlyTurnOn"
                            @dataPointSelection="
                              dataPointSelectionHandlerBarMonthly
                            "
                          ></apexchart></b-col></b-row></b-card-body></b-card></b-col></b-row></b-card-body></b-card></b-col
      ></b-row>

      <!-- <b-row><b-col>END HERE</b-col></b-row> -->

      <b-row
        ><b-col>
          <card
            type="default"
            header-classes="bg-transparent"
            class="text-center border border-white;"
            v-if="model.project_type_id == 1"
            ><b-row>
              <b-col xl="3"
                ><card
                  type="default"
                  header-classes="bg-transparent"
                  class="border border-white; h-100"
                  ><h5 class="h5 text-light bg-dark">COMPLETED</h5>
                  <h5 class="p-5 h1 text-dark bg-light">
                    {{ completedProject[0].completed_project_total }}
                  </h5>
                  <h5 class="h5 text-light bg-dark">
                    {{
                      completedProject[0].completed_project_total_capacity_total
                    }}
                    MWp
                  </h5>
                  <h5 class="h5 text-light bg-dark">
                    Avg:
                    {{ completedProject[0].average_days_complete_project }}
                    days/project
                  </h5>
                </card>
              </b-col>
              <b-col xl="9"
                ><card
                  type="default"
                  header-classes="bg-transparent"
                  class="border border-white; h-100"
                  ><apexchart
                    height="300"
                    width="100%"
                    type="line"
                    :options="chartOptionsUnfinishedProject"
                    :series="seriesUnfinishedProject"
                  ></apexchart>
                </card>
              </b-col>
            </b-row>
          </card>
          <card
            type="default"
            header-classes="bg-transparent"
            class="text-center border border-white;"
            v-if="model.project_type_id == 1"
            ><b-row
              ><b-col xl="12"
                ><card
                  type="default"
                  header-classes="bg-transparent"
                  class="border border-white; h-100"
                  ><h5 class="h1 text-white">AT RISK & DELAYED</h5></card
                ></b-col
              ></b-row
            >
            <div class="text-left">
              <h5 class="h5 text-white">BY MILESTONE</h5>
            </div>
            <div>
              <apexchart
                height="290"
                type="bar"
                :options="chartOptionsByMilestoneMwp"
                :series="seriesByMilestoneMwp"
              ></apexchart>
            </div>
            <!-- <div>
          <apexchart
            height="290"
            type="bar"
            :options="chartOptionsByMilestone"
            :series="seriesByMilestone"
          ></apexchart>
        </div> -->
            <div class="text-left">
              <h5 class="h5 text-white">BY PROJECT MANAGER</h5>
            </div>
            <div>
              <apexchart
                height="290"
                type="bar"
                :options="chartOptionsByManagerMwp"
                :series="seriesByManagerMwp"
              ></apexchart>
            </div>
            <!-- <div>
          <apexchart
            height="290"
            type="bar"
            :options="chartOptionsByManager"
            :series="seriesByManager"
          ></apexchart>
        </div> -->
            <div class="text-left">
              <h5 class="h5 text-white">BY CONTRACTOR</h5>
            </div>
            <div>
              <apexchart
                height="290"
                type="bar"
                :options="chartOptionsByContractorMwp"
                :series="seriesByContractorMwp"
              ></apexchart>
            </div>
            <!-- <div>
          <apexchart
            height="290"
            type="bar"
            :options="chartOptionsByContractor"
            :series="seriesByContractor"
          ></apexchart>
        </div> -->
            <div class="text-left">
              <h5 class="h5 text-white">BY PROJECT</h5>
            </div>
            <b-row
              ><b-col xl="2"
                ><p class="font-weight-bold text-light bg-dark">
                  Project Name - Capacity
                </p></b-col
              ><b-col xl="10"
                ><p class="font-weight-bold text-light bg-dark">
                  Milestones
                </p></b-col
              ></b-row
            >
            <b-row
              ><b-col xl="2"
                ><p class="text-light bg-dark">
                  {{ topCapacity[0].project_name }} -
                  {{ topCapacity[0].top_capacity / 1000 }}MWp
                </p></b-col
              ><b-col xl="10"
                ><vue-step-progress-indicator
                  :steps="[
                    'Design',
                    'Authority Submission',
                    'Procurement',
                    'Hoisting',
                    'Installation',
                    'Test & Comm',
                  ]"
                  :active-step="stepstepstep[0]"
                  :is-reactive="false" /></b-col></b-row
            ><b-row
              ><b-col xl="2"
                ><p class="text-light bg-dark">
                  {{ topCapacity[1].project_name }} -
                  {{ topCapacity[1].top_capacity / 1000 }}MWp
                </p></b-col
              ><b-col xl="10"
                ><vue-step-progress-indicator
                  :steps="[
                    'Design',
                    'Authority Submission',
                    'Procurement',
                    'Hoisting',
                    'Installation',
                    'Test & Comm',
                  ]"
                  :active-step="stepstepstep[1]"
                  :is-reactive="false" /></b-col></b-row
            ><b-row
              ><b-col xl="2"
                ><p class="text-light bg-dark">
                  {{ topCapacity[2].project_name }} -
                  {{ topCapacity[2].top_capacity / 1000 }}MWp
                </p></b-col
              ><b-col xl="10"
                ><vue-step-progress-indicator
                  :steps="[
                    'Design',
                    'Authority Submission',
                    'Procurement',
                    'Hoisting',
                    'Installation',
                    'Test & Comm',
                  ]"
                  :active-step="stepstepstep[2]"
                  :is-reactive="false" /></b-col></b-row
            ><b-row
              ><b-col xl="2"
                ><p class="text-light bg-dark">
                  {{ topCapacity[3].project_name }} -
                  {{ topCapacity[3].top_capacity / 1000 }}MWp
                </p></b-col
              ><b-col xl="10"
                ><vue-step-progress-indicator
                  :steps="[
                    'Design',
                    'Authority Submission',
                    'Procurement',
                    'Hoisting',
                    'Installation',
                    'Test & Comm',
                  ]"
                  :active-step="stepstepstep[3]"
                  :is-reactive="false" /></b-col></b-row
            ><b-row
              ><b-col xl="2"
                ><p class="text-light bg-dark">
                  {{ topCapacity[4].project_name }} -
                  {{ topCapacity[4].top_capacity / 1000 }}MWp
                </p></b-col
              ><b-col xl="10"
                ><vue-step-progress-indicator
                  :steps="[
                    'Design',
                    'Authority Submission',
                    'Procurement',
                    'Hoisting',
                    'Installation',
                    'Test & Comm',
                  ]"
                  :active-step="stepstepstep[4]"
                  :is-reactive="false"
                  @onStepChanged="onStepChanged"
                  @onEnterFinalStep="onEnterFinalStep" /></b-col
            ></b-row>
          </card> </b-col
      ></b-row>
      <!-- <div>{{ stepstepstep }}</div> -->
    </b-container>
  </div>
</template>
<script>
// Charts
import VueApexCharts from "vue-apexcharts";
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import { Charts } from "@/components/Charts/config";

// Components
import BaseProgress from "@/components/BaseProgress";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import StatsCard from "@/components/Cards/StatsCard";

import DocumentSummary from "./ChartComponent/DocumentSummary.vue";
import TurnOnSummary from "./ChartComponent/TurnOnSummary.vue";
import ProjectSummary from "./ChartComponent/ProjectSummary.vue";

import ProjectOverdue from "./ChartComponent/ProjectOverdue.vue";
import TurnOnOverdue from "./ChartComponent/TurnOnOverdue.vue";
import DocumentOverdue from "./ChartComponent/DocumentOverdue.vue";

import MilestoneProgressBar from "./ChartComponent/MilestoneProgressBar.vue";

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import Card from "../../../components/Cards/Card.vue";

function randomScalingFactor() {
  return Math.round(Math.random() * 100);
}

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    apexchart: VueApexCharts,
    LineChart,
    BarChart,
    BaseProgress,
    RouteBreadCrumb,
    StatsCard,
    "document-summary": DocumentSummary,
    "turn-on-summary": TurnOnSummary,
    "project-summary": ProjectSummary,
    "project-overdue": ProjectOverdue,
    "turn-on-overdue": TurnOnOverdue,
    "document-overdue": DocumentOverdue,
    "vue-step-progress-indicator": MilestoneProgressBar,
    Card,
  },
  created() {
    this.getProjectType();
    var currentDate = getcurrentDate();
    this.model.start_date = "2021-05-21";
    this.model.end_date = currentDate;
    this.changeAllChart();
    // this.getDailyOngoingProject();
    // this.getDailyOngoingProjectStatusCNI();
    // this.getDailyOngoingProjectStatusSN();
    // this.getProjectType();
  },
  data() {
    return {
      completedProject: [
        {
          average_days_complete_project: 3,
          completed_project_total: 1,
          completed_project_total_capacity_total: 2,
        },
      ],
      chartOptionsUnfinishedProject: {
        chart: {
          stacked: false,
          // background: "#ddebf6",
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: ["On Track", "At Risk", "Delayed"],
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: true,
          offsetY: 3,
        },
        xaxis: {
          labels: {
            style: {
              colors: ["#34c848", "#feee96", "#fd5754"],
              fontSize: "20px",
              fontWeight: "bold",
            },
          },
        },
        yaxis: [
          {
            min: 0,
            max: 20,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              //   color: "#000000",
            },
            labels: {
              style: {
                // colors: "#000000",
                colors: "#FFFFFF",
              },
            },
            title: {
              text: "Projects",
              style: {
                // color: "#FF1654",
              },
            },
          },
          {
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              //   color: "#000000",
            },
            labels: {
              style: {
                colors: "#000000",
              },
            },
            title: {
              text: "Turn On Capacity (MWp)",
              style: {
                // color: "#247BA0",
              },
            },
          },
        ],
        // tooltip: { shared: false },
        tooltip: { shared: true, intersect: false },
        theme: {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      seriesUnfinishedProject: [
        {
          name: "Projects",
          type: "column",
          data: [1, 2, 3],
        },
        {
          name: "Turn On Capacity (MWp)",
          type: "area",
          data: [2, 3, 4],
        },
      ],
      chartOptionsByMilestoneMwp: {
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enable: true,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          title: { text: "Milestones", style: { fontSize: "20px" } },
          categories: [
            "Design",
            "Authority Submission",
            "Procurement",
            "Hoisting",
            "Installation",
            "Test & Commissioning",
          ],
          labels: { style: { fontSize: "14px" } },
        },
        yaxis: {
          title: {
            text: "Capacity (MWp)",
            style: { fontSize: "20px" },
          },
        },
        fill: {
          opacity: 1,
          colors: "#fd5754",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " MWp";
            },
            title: {
              formatter: function (val) {
                return "Capacity:";
              },
            },
          },
        },
        theme: {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      seriesByMilestoneMwp: [
        {
          name: "milestones",
          data: [1, 2, 3, 4, 5, 6],
          data: [0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptionsByMilestone: {
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enable: true,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          title: { text: "Milestones", style: { fontSize: "20px" } },
          categories: [
            "Design",
            "Authority Submission",
            "Procurement",
            "Hoisting",
            "Installation",
            "Test & Commissioning",
          ],
          labels: { style: { fontSize: "14px" } },
        },
        yaxis: {
          title: {
            text: "# of projects",
            style: { fontSize: "20px" },
          },
        },
        fill: {
          opacity: 1,
          colors: "#fd5754",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
            title: {
              formatter: function (val) {
                return "Projects:";
              },
            },
          },
        },
        theme: {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      seriesByMilestone: [
        {
          name: "milestones",
          data: [1, 2, 3, 4, 5, 6],
        },
      ],
      chartOptionsByManagerMwp: {
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enable: true,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          title: { text: "Project Managers", style: { fontSize: "20px" } },
          // categories: ["PM_1", "PM_2", "PM_3", "PM_4", "PM_5"],
          categories: ["", "", "", "", ""],
          labels: { style: { fontSize: "14px" } },
        },
        yaxis: {
          title: {
            text: "Capacity (MWp)",
            style: { fontSize: "20px" },
          },
        },
        fill: {
          opacity: 1,
          colors: "#fd5754",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " MWp";
            },
            title: {
              formatter: function (val) {
                return "Capacity:";
              },
            },
          },
          // y: {
          //   formatter: function (val) {
          //     return "$ " + val + " thousands";
          //   },
          // },
        },
        theme: {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      seriesByManagerMwp: [
        {
          name: "managers",
          // data: [1, 2, 3, 4, 5],
          data: [0, 0, 0, 0, 0],
        },
      ],
      chartOptionsByManager: {
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enable: true,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          title: { text: "Project Managers", style: { fontSize: "20px" } },
          categories: ["PM_1", "PM_2", "PM_3", "PM_4", "PM_5"],
          labels: { style: { fontSize: "14px" } },
        },
        yaxis: {
          title: {
            text: "# of projects",
            style: { fontSize: "20px" },
          },
        },
        fill: {
          opacity: 1,
          colors: "#fd5754",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
            title: {
              formatter: function (val) {
                return "Projects:";
              },
            },
          },
          // y: {
          //   formatter: function (val) {
          //     return "$ " + val + " thousands";
          //   },
          // },
        },
        theme: {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      seriesByManager: [
        {
          name: "managers",
          data: [1, 2, 3, 4, 5],
        },
      ],
      chartOptionsByContractorMwp: {
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enable: true,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          title: { text: "Contractors", style: { fontSize: "20px" } },
          // categories: ["PC_1", "PC_2", "PC_3", "PC_4", "PC_5"],
          categories: ["", "", "", "", ""],
          labels: { style: { fontSize: "14px" } },
        },
        yaxis: {
          title: {
            text: "Capacity (MWp)",
            style: { fontSize: "20px" },
          },
        },
        fill: {
          opacity: 1,
          colors: "#fd5754",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " MWp";
            },
            title: {
              formatter: function (val) {
                return "Capacity:";
              },
            },
          },
          // y: {
          //   formatter: function (val) {
          //     return "$ " + val + " thousands";
          //   },
          // },
        },
        theme: {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      seriesByContractorMwp: [
        {
          name: "contractors",
          // data: [1, 2, 3, 4, 5],
          data: [0, 0, 0, 0, 0],
        },
      ],
      chartOptionsByContractor: {
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enable: true,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          title: { text: "Contractors", style: { fontSize: "20px" } },
          categories: ["PC_1", "PC_2", "PC_3", "PC_4", "PC_5"],
          labels: { style: { fontSize: "14px" } },
        },
        yaxis: {
          title: {
            text: "# of projects",
            style: { fontSize: "20px" },
          },
        },
        fill: {
          opacity: 1,
          colors: "#fd5754",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
            title: {
              formatter: function (val) {
                return "Projects:";
              },
            },
          },
          // y: {
          //   formatter: function (val) {
          //     return "$ " + val + " thousands";
          //   },
          // },
        },
        theme: {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      seriesByContractor: [
        {
          name: "contractors",
          data: [1, 2, 3, 4, 5],
        },
      ],
      topCapacity: [
        { project_name: "Project 1", top_capacity: 1, top_milestone: 1 },
        { project_name: "Project 2", top_capacity: 1, top_milestone: 1 },
        { project_name: "Project 3", top_capacity: 1, top_milestone: 1 },
        { project_name: "Project 4", top_capacity: 1, top_milestone: 1 },
        { project_name: "Project 5", top_capacity: 1, top_milestone: 1 },
      ],
      project_types: [],
      model: {
        project_type_id: 0,
        project_overdue_total_site: 0,
        start_date: null,
        end_date: null,
        checkboxes: 1,
        title: "CONSOLIDATED",
        radios: {
          radio1: "CONSOLIDATED",
          radio2: "HIDE",
        },
      },
      overdue_highest: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        high_message: null,
      },
      overdue_turn_on: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        high_message: null,
        overdue_total_mwp: 0,
      },
      barChartStacked: {
        chartData: {
          labels: ["March", "April", "May", "June", "July"],
          datasets: [
            {
              label: "SN2 Actual",
              backgroundColor: Charts.colors.theme["success"],
              data: [1, 1.5, 1.2, 2.9, 2.6],
            },
            {
              label: "SN3 Actual",
              backgroundColor: Charts.colors.theme["primary"],
              data: [1.3, 2.3, 2.8, 3.8, 4.4],
            },
          ],
        },
        extraOptions: {
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      },
      bigLineChart: {
        allData: [
          [22.2, 6.6, 2.8, 9.0, 2.6, 0.0, 0.9, 2.2, 0.0, 6.3],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            },
          ],
          labels: [
            "March",
            "April",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ["March", "April", "May", "June", "July"],
          datasets: [
            {
              label: "MWp",
              data: [1.0, 2.3, 2.8, 3.8, 4.4],
            },
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      seriesProjectOverdue: [],
      ChartOptionsProjectOverdue: {
        colors: ["#119ee1", "#0c765a", "#ef8215", "#F43314"],
        chart: {
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: ["< 30 Days", "30 - 60 Days", "60 - 90 Days", "> 90 Days"],
        dataLabels: {
          enabled: true,
        },
        fill: {
          // type: 'gradient',
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
          labels: {
            colors: ["white"],
            useSeriesColors: false,
          },
          position: "bottom",
        },
        title: {
          text: "",
        },
      },
      seriesOverDueTurnOn: [],
      ChartOptionsOverDueTurnOn: {
        colors: ["#119ee1", "#0c765a", "#ef8215", "#F43314"],
        chart: {
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: [
          "Project < 1 MWp",
          "Project 1 - 5 MWp",
          "Project 5 - 10 MWp",
          "Project > 10 MWp",
        ],
        dataLabels: {
          enabled: true,
        },
        fill: {
          // type: 'gradient',
        },

        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
          labels: {
            colors: ["white"],
            useSeriesColors: false,
          },
          position: "bottom",
        },
        title: {
          text: "",
        },
      },
      project_turn_on: {
        total_est: 0,
        total_turn_on: 0,
      },
      seriesProjectTurnOn: [],
      ChartOptionsProjectTurnOn: {
        colors: ["#119ee1", "#0c765a", "#ef8215"],
        chart: {
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: ["Pending", "Completed", "Overdue"],
        dataLabels: {
          enabled: true,
        },
        fill: {
          // type: 'gradient',
        },

        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
          labels: {
            colors: ["white"],
            useSeriesColors: false,
          },
          position: "bottom",
        },
        title: {
          text: "",
        },
      },
      document_summary: {
        document_total: 0,
        document_completed: 0,
      },
      seriesDocumentSummary: [],
      ChartOptionsDocumentSummary: {
        colors: ["#119ee1", "#0c765a", "#ef8215"],
        chart: {
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: ["Pending", "Completed", "Overdue"],
        dataLabels: {
          enabled: true,
        },
        fill: {
          // type: 'gradient',
        },

        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
          labels: {
            colors: ["white"],
            useSeriesColors: false,
          },
          position: "bottom",
        },
        title: {
          text: "",
        },
      },
      document_overdue: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        message: null,
        total_overdue: 0,
      },
      seriesDocumentOverdue: [],
      ChartOptionsDocumentOverdue: {
        colors: ["#119ee1", "#0c765a", "#ef8215", "#F43314"],
        chart: {
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: ["< 30 Days", "30 - 60 Days", "60 - 90 Days", "> 90 Days"],
        dataLabels: {
          enabled: true,
        },
        fill: {
          // type: 'gradient',
        },

        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
          labels: {
            colors: ["white"],
            useSeriesColors: false,
          },
          position: "bottom",
        },
        title: {
          text: "",
        },
      },
      project_milestone_summary: {
        total_pending: 0,
        total_turn_on: 0,
      },
      seriesProjectMilestoneSummary: [],
      ChartOptionsProjectMilestoneSummary: {
        colors: [
          "#119ee1",
          "#0c765a",
          "#5fc7bc",
          "#bc5fc7",
          "#EADE1F",
          "#ef8215",
          "#F43314",
          "#BA4A00",
        ],
        chart: {
          width: 380,
          height: 100,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: [],
        dataLabels: {
          enabled: true,
        },
        fill: {},
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
          labels: {
            colors: ["white"],
            useSeriesColors: false,
          },
          position: "bottom",
        },
        title: {
          text: "",
        },
      },
      top_overdue_project: {
        top_1_site: null,
        top_2_site: null,
        top_3_site: null,
        top_4_site: null,
        top_5_site: null,
        top_1_mwp: 0,
        top_2_mwp: 0,
        top_3_mwp: 0,
        top_4_mwp: 0,
        top_5_mwp: 0,
      },
      top_overdue_document: {
        top_1_site: null,
        top_2_site: null,
        top_3_site: null,
        top_4_site: null,
        top_5_site: null,
        top_1_document: 0,
        top_2_document: 0,
        top_3_document: 0,
        top_4_document: 0,
        top_5_document: 0,
      },
      // stepstepstep: [0, 1, 2, 3, 4],
      stepstepstep: [0, 0, 0, 0, 0],
      model_ongoing: {
        total_ongoing_mwp: 0,
        total_ongoing_site: 0,
        cni_ongoing_mwp: 0,
        cni_ongoing_site: 0,
        sn_ongoing_mwp: 0,
        sn_ongoing_site: 0,
      },
      cardClicked: false,
      chartOptionsPieCNI: {
        chart: {
          // width: 380,
          type: "pie",
        },
        labels: [
          "Design & Engineering",
          "Authority Submission",
          "Hoisting",
          "Installation",
          "Turn On",
          "Others",
        ],
        legend: {
          labels: {
            colors: "#FFFFFF",
          },
        },
        colors: [
          "#549e39",
          "#c0cf3a",
          "#4ab5c4",
          "#325f22",
          "#76801f",
          "#28707a",
        ],
        responsive: [
          {
            // breakpoint: 1,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      // seriesPieCNI: [16.6, 16.6, 16.6, 16.6, 16.6, 16.6],
      seriesPieCNI: [1, 1, 1, 1, 1, 1],
      // seriesPieCNI: [18.5, 12.4, 7.2, 46.4, 12.4, 3.1],
      //   seriesByMilestoneMwp: [
      //   {
      //     name: "milestones",
      //     data: [1, 2, 3, 4, 5, 6],
      //     data: [0, 0, 0, 0, 0, 0],
      //   },
      // ],
      // seriesPieCNI: [
      //   { name: "milestones", data: [18.5, 12.4, 7.2, 46.4, 12.4, 3.1] },
      // ],
      chartOptionsBarCNI: {
        chart: {
          // height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        colors: [
          "#549e39",
          "#c0cf3a",
          "#4ab5c4",
          "#325f22",
          "#76801f",
          "#28707a",
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
            distributed: true,
          },
        },
        dataLabels: {
          formatter: function (value) {
            return value;
          },
          // formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          // // formatter: function (val, opts) {
          //   return typeof(w.globals.seriesSiteCount);
          //   // return opts.w.globals.seriesSiteCount;
          // },
          // formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          //   return value;

          //   // var returnThis =
          //   //   "value: " +
          //   //   value +
          //   //   "\nseriesIndex: " +
          //   //   seriesIndex +
          //   //   "\ndataPointIndex: " +
          //   //   dataPointIndex +
          //   //   "\nw: " +
          //   //   w;
          //   // return returnThis;

          //   // var progress =
          //   //   w.config.series[seriesIndex].data[dataPointIndex].extra[0];
          //   // if (progress != null) {
          //   //   return progress + "%";
          //   // } else {
          //   //   return "";
          //   // }

          //   // var siteCount = w.config.series[seriesIndex].extra[dataPointIndex];
          //   // return siteCount;
          // },
          offsetY: -20,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        legend: {
          show: false,
        },
        xaxis: {
          title: {
            text: "MILESTONES",
            style: { color: "#55c8e9", fontSize: "16px" },
          },
          categories: ["", "", "", "", "", ""],
        },
        yaxis: {
          title: {
            text: "CAPACITY (KWP)",
            style: { color: "#55c8e9", fontSize: "16px" },
          },
          labels: { style: { colors: ["#FFFFFF"] } },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
            title: {
              formatter: function (val) {
                return "Capacity:";
              },
            },
          },
        },
      },
      seriesBarCNI: [
        {
          // data: [300, 200, 150, 700, 150, 100],
          name: "123",
          data: [0, 0, 0, 0, 0, 0],
          extra: [1, 2, 3, 4, 5, 6],
        },
      ],
      chartOptionsPieSN: {
        chart: {
          // width: 380,
          type: "pie",
        },
        labels: [
          "Design & Engineering",
          "Authority Submission",
          "Hoisting",
          "Installation",
          "Turn On",
          "Others",
        ],
        legend: {
          labels: {
            colors: "#FFFFFF",
          },
        },
        colors: [
          "#84acb6",
          "#3494ba",
          "#75bda7",
          "#7a8c8e",
          "#84acb6",
          "#2683c6de",
          // "#549e39",
          // "#c0cf3a",
          // "#4ab5c4",
          // "#325f22",
          // "#76801f",
          // "#28707a",
        ],
        responsive: [
          {
            // breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      // seriesPieSN: [16.6, 16.6, 16.6, 16.6, 16.6, 16.6],
      seriesPieSN: [1, 1, 1, 1, 1, 1],
      // seriesPieCNI: [18.5, 12.4, 7.2, 46.4, 12.4, 3.1],
      //   seriesByMilestoneMwp: [
      //   {
      //     name: "milestones",
      //     data: [1, 2, 3, 4, 5, 6],
      //     data: [0, 0, 0, 0, 0, 0],
      //   },
      // ],
      // seriesPieCNI: [
      //   { name: "milestones", data: [18.5, 12.4, 7.2, 46.4, 12.4, 3.1] },
      // ],
      chartOptionsBarSN: {
        chart: {
          // height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        colors: [
          "#84acb6",
          "#3494ba",
          "#75bda7",
          "#7a8c8e",
          "#84acb6",
          "#2683c6de",
          // "#549e39",
          // "#c0cf3a",
          // "#4ab5c4",
          // "#325f22",
          // "#76801f",
          // "#28707a",
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
            distributed: true,
          },
        },
        dataLabels: {
          offsetY: -20,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        legend: {
          show: false,
        },
        xaxis: {
          title: {
            text: "MILESTONES",
            style: { color: "#55c8e9", fontSize: "16px" },
          },
          categories: ["", "", "", "", "", ""],
        },
        yaxis: {
          title: {
            text: "CAPACITY (KWP)",
            style: { color: "#55c8e9", fontSize: "16px" },
          },
          labels: { style: { colors: ["#FFFFFF"] } },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
            title: {
              formatter: function (val) {
                return "Capacity:";
              },
            },
          },
        },
        // xaxis: {
        //   categories: [
        //     "Design & Engineering",
        //     "Authority Submission",
        //     "Hoisting",
        //     "Installation",
        //     "Turn On",
        //     "Others",
        //   ],
        //   labels: {
        //     style: {
        //       // colors: ["#549e39","#c0cf3a","#4ab5c4","#325f22","#76801f","#28707a"],
        //       colors: "#FFFFFF",
        //       fontSize: "12px",
        //       fontFamily: "Helvetica, Arial, sans-serif",
        //       fontWeight: 400,
        //       cssClass: "apexcharts-xaxis-label",
        //     },
        //   },
        // },
      },
      seriesBarSN: [
        {
          // data: [300, 200, 150, 700, 150, 100],
          data: [0, 0, 0, 0, 0, 0],
        },
      ],
      tempPieDataCNI: "123",
      tempBarDataCNI: "234",
      tempPieDataSN: "345",
      tempBarDataSN: "456",
      getDailyOngoingProjectStatusCNIResponse: null,
      getDailyOngoingProjectStatusSNResponse: null,
      seriesSiteCount: [1, 2, 3, 4, 5, 6],
      chartOptionsMonthlyTurnOn: {
        chart: {
          type: "bar",
          // height: 350,
        },
        colors: ["#76801f", "#3494ba"],
        plotOptions: {
          // bar: {
          //   horizontal: false,
          //   columnWidth: "55%",
          //   endingShape: "rounded",
          // },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { labels: { colors: "#FFFFFF" } },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
          // width: 2,
          // colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          title: {
            text: "MONTHS",
            style: { color: "#55c8e9", fontSize: "16px" },
          },
          labels: {
            style: {
              colors: [
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
              ],
            },
          },
        },
        yaxis: {
          title: {
            text: "CAPACITY (KWP)",
            style: { color: "#55c8e9", fontSize: "16px" },
          },
          labels: { style: { colors: ["#FFFFFF"] } },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
            title: {
              formatter: function (val) {
                return "Capacity:";
              },
            },
            // formatter: function (val) {
            //   return "$ " + val + " thousands";
            // },
          },
        },
      },
      seriesMonthlyTurnOn: [
        // { data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
        {
          name: "C&I",
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        {
          name: "Solar Nova",
          data: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
        },
      ],
      tempArrayCNI: [],
      tempArraySN: [],
    };
  },
  methods: {
    dataPointSelectionHandlerBarMonthly(e, chartContext, config) {
      var deductMonth = 12 - (config.dataPointIndex + 1);
      var inputDate = new Date(this.model.end_date);
      inputDate.setMonth(inputDate.getMonth() - deductMonth);
      var queryYear = inputDate.toLocaleString("default", { year: "numeric" });
      var queryMonth = inputDate.toLocaleString("default", {
        month: "2-digit",
      });

      if (config.seriesIndex == 0) {
        let route = this.$router.resolve({
          path:
            "projectSiteListingTurnOnMonthlyCNI/" +
            this.model.end_date +
            "/" +
            queryYear +
            "/" +
            queryMonth,
        });
        window.open(route.href, "_blank");
      } else {
        let route = this.$router.resolve({
          path:
            "projectSiteListingTurnOnMonthlySN/" +
            this.model.end_date +
            "/" +
            queryYear +
            "/" +
            queryMonth,
        });
        window.open(route.href, "_blank");
      }
    },
    updateSeriesMonthlyTurnOn() {
      this.seriesMonthlyTurnOn = [
        {
          name: "C&I",
          data: this.tempArrayCNI,
        },
        {
          name: "Solar Nova",
          data: this.tempArraySN,
        },
      ];
      this.updateMonthlyTurnOnCategories();
    },
    getDailyOngoingProjectMonthlyTurnOnCNI() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_ongoing_project_monthly_turn_on_cni/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var tempMonthlyArray = [];
            for (const [key, value] of Object.entries(response.data.data[0])) {
              tempMonthlyArray.push(value);
            }
            this.tempArrayCNI = tempMonthlyArray.reverse();
            this.updateSeriesMonthlyTurnOn();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Monthly Turn On CNI");
        });
    },
    getDailyOngoingProjectMonthlyTurnOnSN() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_ongoing_project_monthly_turn_on_sn/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var tempMonthlyArray = [];
            for (const [key, value] of Object.entries(response.data.data[0])) {
              tempMonthlyArray.push(value);
            }
            this.tempArraySN = tempMonthlyArray.reverse();
            this.updateSeriesMonthlyTurnOn();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Monthly Turn On SN");
        });
    },
    updateMonthlyTurnOnCategories() {
      var inputDate = new Date(this.model.end_date);
      var localTempDate = inputDate;
      var tempArray = [];

      var tempMonth = localTempDate.toLocaleString("default", {
        month: "short",
      });
      var tempYear = localTempDate.toLocaleString("default", {
        year: "2-digit",
      });
      var monthYearString = tempMonth + " '" + tempYear;
      tempArray.push(monthYearString);

      for (let i = 1; i < 12; i++) {
        localTempDate.setMonth(localTempDate.getMonth() - 1);
        var tempMonth = localTempDate.toLocaleString("default", {
          month: "short",
        });
        var tempYear = localTempDate.toLocaleString("default", {
          year: "2-digit",
        });
        var monthYearString = tempMonth + " '" + tempYear;
        tempArray.push(monthYearString);
      }

      this.chartOptionsMonthlyTurnOn = {
        xaxis: {
          categories: tempArray.reverse(),
        },
      };
    },
    updateCNISeries() {
      this.seriesPieCNI = [
        this.getDailyOngoingProjectStatusCNIResponse[0].total_design_kwp,
        this.getDailyOngoingProjectStatusCNIResponse[0].total_authority_kwp,
        this.getDailyOngoingProjectStatusCNIResponse[0].total_hoisting_kwp,
        this.getDailyOngoingProjectStatusCNIResponse[0].total_installation_kwp,
        this.getDailyOngoingProjectStatusCNIResponse[0].total_turn_on_kwp,
        this.getDailyOngoingProjectStatusCNIResponse[0].total_others,
      ];
      // this.seriesPieCNI = [
      //   this.getDailyOngoingProjectStatusCNIResponse[0].design_ratio * 100,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].authority_ratio * 100,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].hoisting_ratio * 100,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].installation_ratio *
      //     100,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].turn_on_ratio * 100,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].others_ratio * 100,
      // ];
      this.seriesBarCNI = [
        {
          name: "Ongoing Project CNI",
          // extra: [
          //   this.getDailyOngoingProjectStatusCNIResponse[0].design_count,
          //   this.getDailyOngoingProjectStatusCNIResponse[0].authority_count,
          //   this.getDailyOngoingProjectStatusCNIResponse[0].hoisting_count,
          //   this.getDailyOngoingProjectStatusCNIResponse[0].installation_count,
          //   this.getDailyOngoingProjectStatusCNIResponse[0].turn_on_count,
          //   this.getDailyOngoingProjectStatusCNIResponse[0].others_count,
          // ],
          data: [
            this.getDailyOngoingProjectStatusCNIResponse[0].total_design_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusCNIResponse[0].total_authority_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusCNIResponse[0].total_hoisting_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusCNIResponse[0].total_installation_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusCNIResponse[0].total_turn_on_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusCNIResponse[0].total_others.toFixed(
              2
            ),
            // (
            //   this.getDailyOngoingProjectStatusCNIResponse[0].total_design_kwp /
            //   1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusCNIResponse[0]
            //     .total_authority_kwp / 1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusCNIResponse[0]
            //     .total_hoisting_kwp / 1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusCNIResponse[0]
            //     .total_installation_kwp / 1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusCNIResponse[0]
            //     .total_turn_on_kwp / 1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusCNIResponse[0].total_others /
            //   1000
            // ).toFixed(2),
          ],
          extra: [
            this.getDailyOngoingProjectStatusCNIResponse[0].design_count,
            this.getDailyOngoingProjectStatusCNIResponse[0].authority_count,
            this.getDailyOngoingProjectStatusCNIResponse[0].hoisting_count,
            this.getDailyOngoingProjectStatusCNIResponse[0].installation_count,
            this.getDailyOngoingProjectStatusCNIResponse[0].turn_on_count,
            this.getDailyOngoingProjectStatusCNIResponse[0].others_count,
          ],
        },
      ];
      // this.seriesSiteCount = [
      //   this.getDailyOngoingProjectStatusCNIResponse[0].design_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].authority_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].hoisting_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].installation_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].turn_on_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].others_count,
      // ];
      // this.seriesBarCNI[0].extra = [
      //   this.getDailyOngoingProjectStatusCNIResponse[0].design_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].authority_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].hoisting_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].installation_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].turn_on_count,
      //   this.getDailyOngoingProjectStatusCNIResponse[0].others_count,
      // ];
    },
    getDailyOngoingProjectStatusCNI() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_ongoing_project_status_cni/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.getDailyOngoingProjectStatusCNIResponse = response.data.data;
            this.updateCNISeries();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Daily Ongoing Project Status CNI");
        });
    },
    dataPointSelectionHandlerPieCNI(e, chartContext, config) {
      this.tempPieDataCNI = [config.seriesIndex, config.dataPointIndex];
      let route = this.$router.resolve({
        path:
          "projectSiteListingOngoingStatusCNI/" +
          config.dataPointIndex +
          "/" +
          this.model.end_date,
      });
      window.open(route.href, "_blank");
    },
    dataPointSelectionHandlerBarCNI(e, chartContext, config) {
      this.tempBarDataCNI = [config.seriesIndex, config.dataPointIndex];
      let route = this.$router.resolve({
        path:
          "projectSiteListingOngoingStatusCNI/" +
          config.dataPointIndex +
          "/" +
          this.model.end_date,
      });
      window.open(route.href, "_blank");
    },
    updateSNSeries() {
      this.seriesPieSN = [
        this.getDailyOngoingProjectStatusSNResponse[0].total_design_kwp,
        this.getDailyOngoingProjectStatusSNResponse[0].total_authority_kwp,
        this.getDailyOngoingProjectStatusSNResponse[0].total_hoisting_kwp,
        this.getDailyOngoingProjectStatusSNResponse[0].total_installation_kwp,
        this.getDailyOngoingProjectStatusSNResponse[0].total_turn_on_kwp,
        this.getDailyOngoingProjectStatusSNResponse[0].total_others,
      ];
      this.seriesBarSN = [
        {
          data: [
            this.getDailyOngoingProjectStatusSNResponse[0].total_design_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusSNResponse[0].total_authority_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusSNResponse[0].total_hoisting_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusSNResponse[0].total_installation_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusSNResponse[0].total_turn_on_kwp.toFixed(
              2
            ),
            this.getDailyOngoingProjectStatusSNResponse[0].total_others.toFixed(
              2
            ),
            // (
            //   this.getDailyOngoingProjectStatusSNResponse[0].total_design_kwp /
            //   1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusSNResponse[0]
            //     .total_authority_kwp / 1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusSNResponse[0]
            //     .total_hoisting_kwp / 1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusSNResponse[0]
            //     .total_installation_kwp / 1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusSNResponse[0].total_turn_on_kwp /
            //   1000
            // ).toFixed(2),
            // (
            //   this.getDailyOngoingProjectStatusSNResponse[0].total_others / 1000
            // ).toFixed(2),
          ],
        },
      ];
    },
    getDailyOngoingProjectStatusSN() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_ongoing_project_status_sn/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.getDailyOngoingProjectStatusSNResponse = response.data.data;
            this.updateSNSeries();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Daily Ongoing Project Status SN");
        });
    },
    dataPointSelectionHandlerPieSN(e, chartContext, config) {
      this.tempPieDataSN = [config.seriesIndex, config.dataPointIndex];
      let route = this.$router.resolve({
        path:
          "projectSiteListingOngoingStatusSN/" +
          config.dataPointIndex +
          "/" +
          this.model.end_date,
      });
      window.open(route.href, "_blank");
    },
    dataPointSelectionHandlerBarSN(e, chartContext, config) {
      this.tempBarDataSN = [config.seriesIndex, config.dataPointIndex];
      let route = this.$router.resolve({
        path:
          "projectSiteListingOngoingStatusSN/" +
          config.dataPointIndex +
          "/" +
          this.model.end_date,
      });
      window.open(route.href, "_blank");
    },
    testAll() {
      let route = this.$router.resolve({
        path: "projectSiteListingOngoingAll/" + this.model.end_date,
      });
      window.open(route.href, "_blank");
    },
    testCNI() {
      let route = this.$router.resolve({
        path: "projectSiteListingOngoingCNI/" + this.model.end_date,
      });
      window.open(route.href, "_blank");
    },
    testSN() {
      let route = this.$router.resolve({
        path: "projectSiteListingOngoingSN/" + this.model.end_date,
      });
      window.open(route.href, "_blank");
    },
    handleAll() {
      this.testAll();
    },
    handleCNI() {
      this.testCNI();
    },
    handleSN() {
      this.testSN();
    },
    cardClick() {
      this.cardClicked = true;
    },
    // h1clicked() {
    //   this.tempMWp = "1234.5";
    // },
    activate() {
      setTimeout(() => (this.isHidden = false), 500);
    },
    changeAllChart() {
      this.getProjectOverdueData();
      this.getOverDueTurnOn();
      this.getProjectTurnOn();
      this.getDocumentSummary();
      this.getDocumentOverdue();
      this.getProjectMilestoneSummary();
      this.getTopOverdueProject();
      this.getTopOverduedocument();

      // this.getAtRiskDelayedTopCapacity();

      this.getCompletedProject();
      this.getDailyUnfinishedTotalCapacity();
      this.getDailyAtRiskDelayedProjMilestoneMwp();
      this.getDailyAtRiskDelayedProjectMilestone();
      this.getDailyAtRiskDelayedProjManagerMwp();
      this.getDailyAtRiskDelayedProjectManager();
      this.getDailyAtRiskDelayedContractorMwp();
      this.getDailyAtRiskDelayedContractor();
      this.getDailyOngoingProject();
      this.getDailyOngoingProjectStatusCNI();
      this.getDailyOngoingProjectStatusSN();
      this.getDailyOngoingProjectMonthlyTurnOnCNI();
      this.getDailyOngoingProjectMonthlyTurnOnSN();
      // this.getAtRiskDelayedTopCapacity();
    },
    getDailyOngoingProject() {
      axios
        .get(
          apiDomain + "dashboard/daily_ongoing_project/" + this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.model_ongoing.total_ongoing_mwp = (
              response.data.data[0].total_ongoing_kwp / 1000
            ).toFixed(2);
            // .toFixed(1);
            this.model_ongoing.total_ongoing_site =
              response.data.data[0].total_ongoing_site;
            this.model_ongoing.cni_ongoing_mwp = (
              response.data.data[0].cni_ongoing_kwp / 1000
            ).toFixed(2);
            this.model_ongoing.cni_ongoing_site =
              response.data.data[0].cni_ongoing_site;
            this.model_ongoing.sn_ongoing_mwp = (
              response.data.data[0].sn_ongoing_kwp / 1000
            ).toFixed(2);
            this.model_ongoing.sn_ongoing_site =
              response.data.data[0].sn_ongoing_site;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Daily Ongoing Project");
        });
    },
    // getDailyOngoingProject() {
    //   axios
    //     .get(
    //       apiDomain +
    //         "dashboard/daily_ongoing_project/" +
    //         this.model.project_type_id +
    //         "/" +
    //         this.model.end_date,
    //       { headers: getHeader() }
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.ongoingProjectResponse = response.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response.status);
    //       this.errorFunction(error, "Daily Ongoing Project");
    //     });
    // },
    getCompletedProject() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_completed_project/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.completedProject[0].average_days_complete_project =
              response.data.data[0].average_days_complete_project;
            this.completedProject[0].completed_project_total =
              response.data.data[0].completed_project_total;
            this.completedProject[0].completed_project_total_capacity_total =
              response.data.data[0].completed_project_total_capacity_total;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "At Risk Delayed Top Capacity");
        });
    },
    getDailyUnfinishedTotalCapacity() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_unfinished_total_capacity/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.seriesUnfinishedProject[0].data = [
              response.data.data[0].on_track_total,
              response.data.data[0].at_risk_total,
              response.data.data[0].delayed_total,
            ];
            this.seriesUnfinishedProject[1].data = [
              response.data.data[0].on_track_total_capacity_total,
              response.data.data[0].at_risk_total_capacity_total,
              response.data.data[0].delayed_total_capacity_total,
            ];
            this.chartOptionsUnfinishedProject = {
              // chart: {
              //   stacked: false,
              // },
              labels: ["On Track", "At Risk", "Delayed"],
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Unfinished Total Capacity");
        });
    },
    getDailyAtRiskDelayedProjMilestoneMwp() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_at_risk_delayed_proj_milestone_mwp/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.seriesByMilestoneMwp[0].data = [
              (
                response.data.data[0].milestone_design_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .milestone_authority_submission_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0].milestone_procurement_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0].milestone_hoisting_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0].milestone_installation_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .milestone_test_and_commissioning_capacity_kwp / 1000
              ).toFixed(2),
            ];
            this.chartOptionsByMilestoneMwp = {
              xaxis: {
                categories: [
                  "Design",
                  "Authority Submission",
                  "Procurement",
                  "Hoisting",
                  "Installation",
                  "Test & Commissioning",
                ],
              },
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Unfinished by Project Milestone MWp");
        });
    },
    getDailyAtRiskDelayedProjectMilestone() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_at_risk_delayed_project_milestone/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.seriesByMilestone[0].data = [
              response.data.data[0].milestone_design_count,
              response.data.data[0].milestone_authority_submission_count,
              response.data.data[0].milestone_procurement_count,
              response.data.data[0].milestone_hoisting_count,
              response.data.data[0].milestone_installation_count,
              response.data.data[0].milestone_test_and_commissioning_count,
            ];
            this.chartOptionsByMilestone = {
              xaxis: {
                categories: [
                  "Design",
                  "Authority Submission",
                  "Procurement",
                  "Hoisting",
                  "Installation",
                  "Test & Commissioning",
                ],
              },
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Unfinished Total Capacity");
        });
    },
    getDailyAtRiskDelayedProjManagerMwp() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_at_risk_delayed_proj_manager_mwp/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.seriesByManagerMwp[0].data = [
              (
                response.data.data[0]
                  .project_manager_1_project_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .project_manager_2_project_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .project_manager_3_project_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .project_manager_4_project_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .project_manager_5_project_total_capacity_kwp / 1000
              ).toFixed(2),
            ];
            this.chartOptionsByManagerMwp = {
              xaxis: {
                categories: [
                  response.data.data[0].project_manager_1,
                  response.data.data[0].project_manager_2,
                  response.data.data[0].project_manager_3,
                  response.data.data[0].project_manager_4,
                  response.data.data[0].project_manager_5,
                ],
              },
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Unfinished by Project Manager MWp");
        });
    },
    getDailyAtRiskDelayedProjectManager() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_at_risk_delayed_project_manager/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.seriesByManager[0].data = [
              response.data.data[0].project_manager_1_project_total,
              response.data.data[0].project_manager_2_project_total,
              response.data.data[0].project_manager_3_project_total,
              response.data.data[0].project_manager_4_project_total,
              response.data.data[0].project_manager_5_project_total,
            ];
            this.chartOptionsByManager = {
              xaxis: {
                categories: [
                  response.data.data[0].project_manager_1,
                  response.data.data[0].project_manager_2,
                  response.data.data[0].project_manager_3,
                  response.data.data[0].project_manager_4,
                  response.data.data[0].project_manager_5,
                ],
              },
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Unfinished Total Capacity");
        });
    },
    getDailyAtRiskDelayedContractorMwp() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_at_risk_delayed_contractor_mwp/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.seriesByContractorMwp[0].data = [
              (
                response.data.data[0]
                  .contractor_1_project_site_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .contractor_2_project_site_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .contractor_3_project_site_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .contractor_4_project_site_total_capacity_kwp / 1000
              ).toFixed(2),
              (
                response.data.data[0]
                  .contractor_5_project_site_total_capacity_kwp / 1000
              ).toFixed(2),
            ];
            this.chartOptionsByContractorMwp = {
              xaxis: {
                categories: [
                  response.data.data[0].contractor_1,
                  response.data.data[0].contractor_2,
                  response.data.data[0].contractor_3,
                  response.data.data[0].contractor_4,
                  response.data.data[0].contractor_5,
                ],
              },
            };
            this.activate();
            this.getAtRiskDelayedTopCapacity();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Unfinished by Contractor MWp");
        });
    },
    getDailyAtRiskDelayedContractor() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_at_risk_delayed_contractor/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.seriesByContractor[0].data = [
              response.data.data[0].contractor_1_project_total,
              response.data.data[0].contractor_2_project_total,
              response.data.data[0].contractor_3_project_total,
              response.data.data[0].contractor_4_project_total,
              response.data.data[0].contractor_5_project_total,
            ];
            this.chartOptionsByContractor = {
              xaxis: {
                categories: [
                  response.data.data[0].contractor_1,
                  response.data.data[0].contractor_2,
                  response.data.data[0].contractor_3,
                  response.data.data[0].contractor_4,
                  response.data.data[0].contractor_5,
                ],
              },
            };
            this.activate();
            this.getAtRiskDelayedTopCapacity();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Unfinished Total Capacity");
        });
    },
    getAtRiskDelayedTopCapacity() {
      axios
        .get(
          apiDomain +
            "dashboard/daily_at_risk_delayed_top_capacity/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.topCapacity = response.data.data;
            this.stepstepstep = [
              response.data.data[0].top_milestone - 1,
              response.data.data[1].top_milestone - 1,
              response.data.data[2].top_milestone - 1,
              response.data.data[3].top_milestone - 1,
              response.data.data[4].top_milestone - 1,
            ];
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "At Risk Delayed Top Capacity");
        });
    },
    getTopOverduedocument() {
      axios
        .get(
          apiDomain +
            "dashboard/top_overdue_document/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.top_overdue_document.top_1_site = null;
            this.top_overdue_document.top_1_document = 0;
            this.top_overdue_document.top_2_site = null;
            this.top_overdue_document.top_2_document = 0;
            this.top_overdue_document.top_3_site = null;
            this.top_overdue_document.top_3_document = 0;
            this.top_overdue_document.top_4_site = null;
            this.top_overdue_document.top_4_document = 0;
            this.top_overdue_document.top_5_site = null;
            this.top_overdue_document.top_5_document = 0;

            if (response.data.data[0] != null) {
              this.top_overdue_document.top_1_site =
                response.data.data[0].site_name;
              this.top_overdue_document.top_1_document =
                response.data.data[0].total_overdue_document;
            }

            if (response.data.data[1].site_name != null) {
              this.top_overdue_document.top_2_site =
                response.data.data[1].site_name;
              this.top_overdue_document.top_2_document =
                response.data.data[1].total_overdue_document;
            }

            if (response.data.data[2] != null) {
              this.top_overdue_document.top_3_site =
                response.data.data[2].site_name;
              this.top_overdue_document.top_3_document =
                response.data.data[2].total_overdue_document;
            }

            if (response.data.data[3] != null) {
              this.top_overdue_document.top_4_site =
                response.data.data[3].site_name;
              this.top_overdue_document.top_4_document =
                response.data.data[3].total_overdue_document;
            }

            if (response.data.data[4] != null) {
              this.top_overdue_document.top_5_site =
                response.data.data[4].site_name;
              this.top_overdue_document.top_5_document =
                response.data.data[4].total_overdue_document;
            }
            console.log(this.top_overdue_document);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Top Overdue Project");
        });
    },
    getTopOverdueProject() {
      axios
        .get(
          apiDomain +
            "dashboard/top_overdue_project/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.top_overdue_project.top_1_site = null;
            this.top_overdue_project.top_1_mwp = 0;
            this.top_overdue_project.top_2_site = null;
            this.top_overdue_project.top_2_mwp = 0;
            this.top_overdue_project.top_3_site = null;
            this.top_overdue_project.top_3_mwp = 0;
            this.top_overdue_project.top_4_site = null;
            this.top_overdue_project.top_4_mwp = 0;
            this.top_overdue_project.top_5_site = null;
            this.top_overdue_project.top_5_mwp = 0;

            if (response.data.data[0] != null) {
              this.top_overdue_project.top_1_site =
                response.data.data[0].site_name;
              this.top_overdue_project.top_1_mwp =
                response.data.data[0].overdue_mwp;
            }

            if (response.data.data[1] != null) {
              this.top_overdue_project.top_2_site =
                response.data.data[1].site_name;
              this.top_overdue_project.top_2_mwp =
                response.data.data[1].overdue_mwp;
            }

            if (response.data.data[2] != null) {
              this.top_overdue_project.top_3_site =
                response.data.data[2].site_name;
              this.top_overdue_project.top_3_mwp =
                response.data.data[2].overdue_mwp;
            }

            if (response.data.data[3] != null) {
              this.top_overdue_project.top_4_site =
                response.data.data[3].site_name;
              this.top_overdue_project.top_4_mwp =
                response.data.data[3].overdue_mwp;
            }

            if (response.data.data[4] != null) {
              this.top_overdue_project.top_5_site =
                response.data.data[4].site_name;
              this.top_overdue_project.top_5_mwp =
                response.data.data[4].overdue_mwp;
            }
            console.log(this.top_overdue_project);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Top Overdue Project");
        });
    },
    getProjectMilestoneSummary() {
      axios
        .get(
          apiDomain +
            "dashboard/project_summary/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_milestone_summary.total_pending =
              response.data.data.series[0];
            this.project_milestone_summary.total_turn_on =
              response.data.data.series[1];
            this.seriesProjectMilestoneSummary = response.data.data.series;
            this.ChartOptionsProjectMilestoneSummary = {
              labels: response.data.data.label,
              colors: [
                "#119ee1",
                "#0c765a",
                "#5fc7bc",
                "#bc5fc7",
                "#EADE1F",
                "#ef8215",
                "#F43314",
                "#BA4A00",
              ],
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Milestone Summary");
        });
    },
    getDocumentOverdue() {
      axios
        .get(
          apiDomain +
            "dashboard/document_overdue/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var OverdueData = [
              response.data.data[0].total_overdue_below_30,
              response.data.data[0].total_overdue_30_to_60,
              response.data.data[0].total_overdue_60_to_90,
              response.data.data[0].total_overdue_60_to_90,
            ];

            this.seriesDocumentOverdue = OverdueData;
            this.document_overdue.total_overdue =
              response.data.data[0].total_overdue;

            this.document_overdue.one =
              response.data.data[0].total_overdue_below_30;
            this.document_overdue.two =
              response.data.data[0].total_overdue_30_to_60;
            this.document_overdue.three =
              response.data.data[0].total_overdue_60_to_90;
            this.document_overdue.four =
              response.data.data[0].total_overdue_60_to_90;

            if (
              this.document_overdue.one > this.document_overdue.two &&
              this.document_overdue.one > this.document_overdue.three &&
              this.document_overdue.one > this.document_overdue.four
            ) {
              this.document_overdue.message = "Below 30 Days";
            } else if (
              this.document_overdue.two > this.document_overdue.one &&
              this.document_overdue.two > this.document_overdue.three &&
              this.document_overdue.two > this.document_overdue.four
            ) {
              this.document_overdue.message = "30 - 60 Days";
            } else if (
              this.document_overdue.three > this.document_overdue.one &&
              this.document_overdue.three > this.document_overdue.two &&
              this.document_overdue.three > this.document_overdue.four
            ) {
              this.document_overdue.message = "60 - 90 Days";
            } else if (
              this.document_overdue.four > this.document_overdue.one &&
              this.document_overdue.four > this.document_overdue.two &&
              this.document_overdue.four > this.document_overdue.three
            ) {
              this.document_overdue.message = "More Then 90 Days";
            } else {
              this.document_overdue.message = "All the Same";
            }
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Overdue Project");
        });
    },
    getDocumentSummary() {
      axios
        .get(
          apiDomain +
            "dashboard/document_summary/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var document_summary_data = [
              response.data.data[0].document_pending,
              response.data.data[0].document_completed,
              response.data.data[0].document_overdue,
            ];

            this.seriesDocumentSummary = document_summary_data;
            this.document_summary.document_total =
              response.data.data[0].document_total;
            this.document_summary.document_completed =
              response.data.data[0].document_completed;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Turn On");
        });
    },
    getProjectTurnOn() {
      axios
        .get(
          apiDomain +
            "dashboard/project_turn_on/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var project_turn_on_data = [
              response.data.data[0].pending_total,
              response.data.data[0].completed_total,
              response.data.data[0].overdue_total,
            ];

            this.seriesProjectTurnOn = project_turn_on_data;
            this.project_turn_on.total_est =
              response.data.data[0].project_total;
            this.project_turn_on.total_turn_on =
              response.data.data[0].completed_total;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Turn On");
        });
    },
    getOverDueTurnOn() {
      axios
        .get(
          apiDomain +
            "dashboard/over_due_turn_on/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var OverdueData = [
              response.data.data[0].mwp_below_1,
              response.data.data[0].mwp_1_to_5,
              response.data.data[0].mwp_5_to_10,
              response.data.data[0].mwp_above_10,
            ];

            this.seriesOverDueTurnOn = OverdueData;
            this.overdue_turn_on.overdue_total_mwp =
              response.data.data[0].mwp_total_overdue;

            this.overdue_turn_on.one = response.data.data[0].mwp_below_1;
            this.overdue_turn_on.two = response.data.data[0].mwp_1_to_5;
            this.overdue_turn_on.three = response.data.data[0].mwp_5_to_10;
            this.overdue_turn_on.four = response.data.data[0].mwp_above_10;

            if (
              this.overdue_turn_on.one > this.overdue_turn_on.two &&
              this.overdue_turn_on.one > this.overdue_turn_on.three &&
              this.overdue_turn_on.one > this.overdue_turn_on.four
            ) {
              this.overdue_turn_on.high_message = "Project < 1 MWp";
            } else if (
              this.overdue_turn_on.two > this.overdue_turn_on.one &&
              this.overdue_turn_on.two > this.overdue_turn_on.three &&
              this.overdue_turn_on.two > this.overdue_turn_on.four
            ) {
              this.overdue_turn_on.high_message = "Project 1 - 5 MWp";
            } else if (
              this.overdue_turn_on.three > this.overdue_turn_on.one &&
              this.overdue_turn_on.three > this.overdue_turn_on.two &&
              this.overdue_turn_on.three > this.overdue_turn_on.four
            ) {
              this.overdue_turn_on.high_message = "Project 5 - 10 MWp";
            } else if (
              this.overdue_turn_on.four > this.overdue_turn_on.one &&
              this.overdue_turn_on.four > this.overdue_turn_on.two &&
              this.overdue_turn_on.four > this.overdue_turn_on.three
            ) {
              this.overdue_turn_on.high_message = "Project > 10 MWp";
            } else {
              this.overdue_highest.high_message = "All the Same";
            }
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Overdue Turn On");
        });
    },
    getProjectOverdueData() {
      axios
        .get(
          apiDomain +
            "dashboard/over_due_project/" +
            this.model.project_type_id +
            "/" +
            this.model.end_date,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var OverdueData = [
              response.data.data[0].total_overdue_below_30,
              response.data.data[0].total_overdue_30_to_60,
              response.data.data[0].total_overdue_60_to_90,
              response.data.data[0].total_overdue_60_to_90,
            ];

            this.seriesProjectOverdue = OverdueData;
            this.model.project_overdue_total_site =
              response.data.data[0].total_site;

            this.overdue_highest.one =
              response.data.data[0].total_overdue_below_30;
            this.overdue_highest.two =
              response.data.data[0].total_overdue_30_to_60;
            this.overdue_highest.three =
              response.data.data[0].total_overdue_60_to_90;
            this.overdue_highest.four =
              response.data.data[0].total_overdue_60_to_90;

            if (
              this.overdue_highest.one > this.overdue_highest.two &&
              this.overdue_highest.one > this.overdue_highest.three &&
              this.overdue_highest.one > this.overdue_highest.four
            ) {
              this.overdue_highest.high_message = "Below 30 Days";
            } else if (
              this.overdue_highest.two > this.overdue_highest.one &&
              this.overdue_highest.two > this.overdue_highest.three &&
              this.overdue_highest.two > this.overdue_highest.four
            ) {
              this.overdue_highest.high_message = "30 - 60 Days";
            } else if (
              this.overdue_highest.three > this.overdue_highest.one &&
              this.overdue_highest.three > this.overdue_highest.two &&
              this.overdue_highest.three > this.overdue_highest.four
            ) {
              this.overdue_highest.high_message = "60 - 90 Days";
            } else if (
              this.overdue_highest.four > this.overdue_highest.one &&
              this.overdue_highest.four > this.overdue_highest.two &&
              this.overdue_highest.four > this.overdue_highest.three
            ) {
              this.overdue_highest.high_message = "More Then 90 Days";
            } else {
              this.overdue_highest.high_message = "All the Same";
            }
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Overdue Project");
        });
    },
    getProjectType() {
      axios
        .get(apiDomain + "setup/project_type", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.project_types = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Type");
        });
    },
    changetitle() {
      this.model.title = this.model.radios.radio1;
    },
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Year 2021 Monthly Target",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: [
          "March",
          "April",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
  },
  mounted() {
    this.initBigChart(0);
  },
};
</script>
<style></style>
